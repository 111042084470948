/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/no-unsafe-return  */
/* eslint-disable @typescript-eslint/no-unsafe-member-access  */
/* eslint-disable @typescript-eslint/no-unsafe-argument  */

import * as d3 from 'd3';
import { interpolatePath } from 'd3-interpolate-path';

import { TransactionType } from '@shared/models/Account';
import { PerformanceFilterRange, PerformanceGraphItem, PerformanceTransactionItem, PerformanceType } from '@shared/models/Home';

export const createSvg = (selector: string, width: number, height: number) => {
  return d3
    .select(selector)
    .attr('width', width)
    .attr('height', height)
    .style('-webkit-tap-highlight-color', 'transparent')
    .style('overflow', 'visible');
};

export enum PerformanceColors {
  POSITIVE = '--color-core-brand-6-5',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  NEGATIVE = '--color-core-brand-6-5',
  BENCHMARK = '--color-core-neutral-5',
  BACKGROUND = '--ion-color-light',
  CORE_NEUTRAL_4 = '--color-core-neutral-4',
  CORE_BRAND_3_3 = '--color-core-brand-3-3'
}

export const performanceFilters = [
  { id: PerformanceFilterRange.WEEK, value: 'PERFORMANCE.performanceChart.dateRangeTabs.1week' },
  { id: PerformanceFilterRange.MONTH, value: 'PERFORMANCE.performanceChart.dateRangeTabs.1month', days: 30 },
  { id: PerformanceFilterRange.HALF_YEAR, value: 'PERFORMANCE.performanceChart.dateRangeTabs.6month', days: 180 },
  { id: PerformanceFilterRange.YEAR, value: 'PERFORMANCE.performanceChart.dateRangeTabs.1year', days: 365 },
  { id: PerformanceFilterRange.ALL, value: 'PERFORMANCE.performanceChart.dateRangeTabs.allTime' },
  { id: PerformanceFilterRange.CURRENT_YEAR, value: new Date().getFullYear() }
];

export const getCssValueFromDom = (variableName: string) => {
  return getComputedStyle(document.documentElement).getPropertyValue(variableName);
};

const getNumberOfDaysTillToday = (startDate: string) => {
  const daysDifference = new Date().getTime() - new Date(startDate).getTime();
  return Math.ceil(daysDifference / (1000 * 3600 * 24));
};

export const getPerformanceFilters = (firstPerformanceDate: string) => {
  const totalDays = getNumberOfDaysTillToday(firstPerformanceDate);

  return performanceFilters.filter((rangeItem) => {
    return !('days' in rangeItem) || rangeItem.days < totalDays;
  });
};

export const getChartMaxMinValues = (data: PerformanceGraphItem[]): { min: number; max: number } => {
  return {
    min: d3.min(data, (d) => (typeof d.benchmarkValue === 'undefined' ? d.value : Math.min(d.value, d.benchmarkValue))),
    max: d3.max(data, (d) => (typeof d.benchmarkValue === 'undefined' ? d.value : Math.max(d.value, d.benchmarkValue)))
  };
};

export const getDepositsAndWithdrawals = (
  data: PerformanceGraphItem[],
  chartType: PerformanceType,
  range: PerformanceFilterRange
): { deposits: PerformanceTransactionItem[]; withdrawals: PerformanceTransactionItem[] } => {
  const result = {
    deposits: [],
    withdrawals: []
  };
  if (chartType === PerformanceType.PERFORMANCE || range === PerformanceFilterRange.ALL || data.length === 0) {
    return result;
  }

  data.forEach((item) => {
    if (item.transactions?.length) {
      for (const transaction of item.transactions) {
        if (transaction.transaction_type === TransactionType.DEPOSIT) {
          result.deposits.push(item);
        }
        if (transaction.transaction_type === TransactionType.WITHDRAWAL) {
          result.withdrawals.push(item);
        }
      }
    }
  });

  return result;
};

export const getChartStartEndValues = (data: PerformanceGraphItem[]): { startItems: PerformanceGraphItem[]; endItems: PerformanceGraphItem[] } => {
  const firstItem = data[0];
  const lastItem = data[data.length - 1];

  const dayBeforeFirst = new Date(firstItem.date.getTime());
  const dayAfterLast = new Date(lastItem.date.getTime());

  dayBeforeFirst.setDate(firstItem.date.getDate() - 1);
  dayAfterLast.setDate(lastItem.date.getDate() + 1);

  return {
    startItems: [{ ...firstItem, date: dayBeforeFirst }, firstItem],
    endItems: [lastItem, { ...lastItem, date: dayAfterLast }]
  };
};

export const getXScale = (data: PerformanceGraphItem[], startValue: number, endValue: number) => {
  return d3
    .scaleTime()
    .nice()
    .range([startValue, endValue])
    .domain(d3.extent(data, (d) => d.date));
};

export const getYScale = (minValue: number, maxValue: number, svgHeight: number) => {
  return d3.scaleLinear().range([svgHeight, 0]).domain([minValue, maxValue]).nice();
};

export const getLine = (xScaleFn: (value: any) => any, yScaleFn: (value: any) => any, key: string, key2: string) => {
  return d3
    .line()
    .defined((d) => d[key])
    .x((d) => xScaleFn(d[key]))
    .y((d) => yScaleFn(d[key2]))
    .curve(d3.curveMonotoneX);
};

export const renderLine = (
  lineClass: string,
  svg,
  lineData,
  lineColor,
  lineColorUpdate: string | null = null,
  lineWidth,
  xScaleFn: (param) => any,
  yScaleFn: (param) => any,
  yKey: string
) => {
  const line = svg.selectAll(`.${lineClass}`).data([lineData]);
  const duration = 500;
  const t = d3.transition().duration(duration);

  return line.data([lineData]).join(
    (enter) => {
      enter
        .append('path')
        .attr('class', lineClass)
        .attr('fill', 'none')
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        .attr('stroke', `var(${lineColor})`)
        .attr('stroke-width', lineWidth)
        .attr(
          'd',
          d3
            .line()
            .x((d) => xScaleFn(d['date']))
            .y(() => yScaleFn(0))
            .curve(d3.curveMonotoneX)
        )
        .attr('stroke-linejoin', 'round')
        .attr('stroke-linecap', 'round')
        .transition(t)
        .attr('d', getLine(xScaleFn, yScaleFn, 'date', yKey));
    },
    (update) => {
      update.transition(t).attrTween('d', (d) => {
        const previous = d3.select(`.${lineClass}`).attr('d');
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const line = getLine(xScaleFn, yScaleFn, 'date', yKey);

        const current = line(d);

        return interpolatePath(previous, current);
      });
      if (lineColorUpdate !== null) {
        update.attr('stroke', `var(${lineColorUpdate})`);
      }
    },
    (exit) => exit.remove()
  );
};

export const getBisect = (mx, data: any[], xScaleFn: (param) => any) => {
  const bisectObj = d3.bisector((d: any) => d.date).left;
  // date value from xScale
  const date = (<any>xScaleFn).invert(mx);
  // get the object index from the data set for selected date
  const index = bisectObj(data, date, 1);
  // get the previous and current data item from
  const a = data[index - 1];
  const b = data[index];

  if (a?.date && b?.date) {
    return date - a.date > b.date - date ? b : a;
  }
  return {
    date: 0,
    value: 0,
    benchmarkValue: 0
  };
};

export const getPerformanceTooltip = (value: number, benchValue: number) => {
  const formattedValue = value / 100;
  const formattedBenchValue = benchValue / 100;
  return [
    {
      percentage: formattedValue
    },
    {
      percentage: formattedBenchValue
    }
  ];
};

export const getBalanceTooltip = (amount: number, transactions: PerformanceTransactionItem[]) => {
  let withdrawals = 0;
  let deposits = 0;

  if (typeof transactions !== 'undefined') {
    transactions.forEach((transaction) => {
      if (transaction.transaction_type === TransactionType.WITHDRAWAL) {
        withdrawals += transaction.transaction_value;
      }
      if (transaction.transaction_type === TransactionType.DEPOSIT) {
        deposits += transaction.transaction_value;
      }
    });
  }

  return {
    amount,
    withdrawals,
    deposits
  };
};

const tickFormat = (date: Date, language: string, numberOfDays: number) => {
  const DAYS_IN_HALF_YEAR = 180;
  let formatOptions: Intl.DateTimeFormatOptions = {};

  if (numberOfDays >= DAYS_IN_HALF_YEAR) {
    formatOptions = { month: 'short', year: 'numeric' };
  } else {
    formatOptions = { day: 'numeric', month: 'short' };
  }

  return new Intl.DateTimeFormat(language, formatOptions).format(date);
};

export const generateBottomAxis = (xScaleFn: (param) => any, chartItems: PerformanceGraphItem[], language: string) => {
  const chunkSize = Math.floor(chartItems.length / 2);

  return d3
    .axisBottom(xScaleFn as d3.AxisScale<any>)
    .tickValues([new Date(chartItems[0].date), new Date(chartItems[chunkSize].date), new Date(chartItems[chartItems.length - 1].date)])
    .tickFormat((d) => {
      return tickFormat(d, language, chartItems.length);
    });
};
