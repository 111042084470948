import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Customer, CustomerAccount, IBANItem, WithdrawalReasons, WithdrawalRequest, WithdrawRequest } from '@inyova/models';

import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as fromRoot from '@app/app.reducers';

import {
  BankAccountDetail,
  FinancialSituation,
  FinancialSituationInvestmentProfile,
  PersonalDetails,
  ProfileBannerItem
} from '@shared/models/Account';
import { BalanceSheet, PerformanceSummary } from '@shared/models/Home';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AccountResources {
  private readonly URL: string;
  private readonly URL_V3: string;
  private accountID: string;

  constructor(
    private http: HttpClient,
    private store: Store
  ) {
    this.store.pipe(select(fromRoot.selectSelectedAccount)).subscribe((account) => (this.accountID = account?.id));
    this.URL = `${environment.apiUrl}`;
    this.URL_V3 = `${environment.apiUrlV3}`;
  }

  getAccountDetails(): Observable<{ data: { attributes: PersonalDetails } }> {
    return this.http.get<{ data: { attributes: PersonalDetails } }>(`${environment.apiUrlV3}/personal_info`);
  }

  getCustomer(): Observable<Customer> {
    return this.http.get<Customer>(`${this.URL}`);
  }

  getCurrentAccount(accountID: string): Observable<CustomerAccount> {
    return this.http.get<CustomerAccount>(`${this.URL}/accounts/${accountID}`);
  }

  updatePassword(data: {
    current_password: string;
    password: string;
    password_confirmation: string;
  }): Observable<{ data: Customer; status: boolean }> {
    return this.http.put<{ data: Customer; status: boolean }>(`${this.URL}/auth`, data);
  }

  updateSavings(data: { period_investment: number }): Observable<{ success: boolean }> {
    return this.http.post<{ success: boolean }>(`${this.URL}/accounts/${this.accountID}/strategy/savings-plan`, data);
  }

  simulateCustomer(token: string): Observable<{ status: string; data: Customer }> {
    return this.http.get<{ status: string; data: Customer }>(`${this.URL}/validate-yova-token/${token}?is_admin=1`);
  }

  getProfileBanners(): Observable<{ data: ProfileBannerItem[]; meta: [] }> {
    return this.http.get<{ data: ProfileBannerItem[]; meta: [] }>(`${this.URL_V3}/profile_banners`);
  }

  getFinancialSituation(): Observable<{ financial_detail: FinancialSituation; investment_profile: FinancialSituationInvestmentProfile }> {
    return this.http.get<{ financial_detail: FinancialSituation; investment_profile: FinancialSituationInvestmentProfile }>(
      `${environment.apiUrl}/accounts/${this.accountID}/account_opening/financial_situation`
    );
  }

  submitGreennessSurvey(data: { greenness_score?: number; net_worth?: string; annual_income?: string }): Observable<any> {
    return this.http.post<any>(`${environment.apiUrlV4}/tracking/greenness_survey_submission`, data);
  }

  getIBANList(): Observable<{ data: IBANItem[]; meta: [] }> {
    return this.http.get<{ data: IBANItem[]; meta: [] }>(`${this.URL_V3}/beneficiaries?filter[account_id]=${this.accountID}`);
  }

  checkCustomerIBAN(body: { iban: string }): Observable<BankAccountDetail> {
    return this.http.post<BankAccountDetail>(`${environment.apiUrl}/account_opening/iban_verification`, body);
  }

  checkWithdrawalInProgress(): Observable<{ data: WithdrawRequest[] }> {
    return this.http.get<{ data: WithdrawRequest[] }>(
      `${this.URL_V3}/new_funds_withdrawal_requests?filter[status]=in_progress&filter[account_id]=${this.accountID}`
    );
  }

  getWithdrawalReasonOptions(): Observable<{ id: number; name: string; survey_questions: WithdrawalReasons[] }> {
    return this.http.get<{ id: number; name: string; survey_questions: WithdrawalReasons[] }>(
      `${environment.apiUrl}/accounts/${this.accountID}/survey/withdrawals_feedback`
    );
  }

  sendWithdrawalRequest(data: WithdrawRequest): Observable<WithdrawRequest> {
    return this.http.post<WithdrawRequest>(`${this.URL_V3}/new_funds_withdrawal_requests`, { data });
  }

  requestNewWithdrawal(payload: WithdrawalRequest): Observable<{ data: WithdrawalRequest }> {
    return this.http.post<{ data: WithdrawalRequest }>(`${this.URL_V3}/funds_withdrawal_requests`, { data: payload });
  }

  requestReInvestment() {
    return this.http.post(`${this.URL_V3}/reinvestment_requests`, { data: { attributes: { account_id: this.accountID } } });
  }

  getPerformanceSummary(): Observable<{ data: { id: string; type: 'summary'; attributes: PerformanceSummary } }> {
    return this.http.get<{ data: { id: string; type: 'summary'; attributes: PerformanceSummary } }>(
      `${this.URL_V3}/performance_summary/${this.accountID}`
    );
  }

  getBalanceSheet(): Observable<{ data: { id: string; type: 'balance_statement'; attributes: BalanceSheet } }> {
    return this.http.get<{ data: { id: string; type: 'balance_statement'; attributes: BalanceSheet } }>(
      `${this.URL_V3}/balance_statement/${this.accountID}`
    );
  }
}
