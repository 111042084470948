import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { getRiskType, RISK_RATIO_TO_SLIDER_STEP } from '@inyova/grow';

import { Subject } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';

import { TrackingService } from '@app/shared/services/tracking.service';
import { getPercentageOfPortfolio } from '@grow/utils/inyova-grow.utils';
import { InyovaGrowValidator } from '@grow/validators/inyova-grow.validator';
import { GrowProject, InyovaGrowAccount } from '@shared/models/Grow';

export enum AmountType {
  RECOMMENDED,
  CUSTOM
}

@Component({
  selector: 'app-inyova-grow-investment-setup',
  templateUrl: './inyova-grow-investment-setup.component.html',
  styleUrls: ['./inyova-grow-investment-setup.component.scss']
})
export class InyovaGrowInvestmentSetupComponent implements OnInit, OnDestroy {
  @Input() growAccount: InyovaGrowAccount;
  @Input() project: GrowProject;
  @Input() language: string;
  @Output() submitAmount: EventEmitter<{ amount: number }> = new EventEmitter<{ amount: number }>();
  protected readonly onDestroy$ = new Subject<void>();
  amountType = AmountType;
  amountForm: FormGroup;
  amountEditMode = false;
  showAmountInfo = false;
  selectedType: AmountType;
  riskLevel = 'balanced';
  hasRecommendedAmount = true;
  percentageOfPortfolio: number;

  get amountControl(): AbstractControl {
    return this.amountForm.get('amount');
  }

  get riskAgreedControl(): AbstractControl {
    return this.amountForm.get('riskAgreed');
  }

  get isCustomAmountCausingClusterRisk(): boolean {
    return this.percentageOfPortfolio >= 0.1;
  }

  constructor(
    private fb: FormBuilder,
    private trackingService: TrackingService
  ) {}

  ngOnInit() {
    this.hasRecommendedAmount =
      this.project.account_specific.recommended_additional_amount !== 0 &&
      this.project.account_specific.recommended_additional_amount >= this.project.minimum_funding;
    this.selectedType = this.hasRecommendedAmount ? AmountType.RECOMMENDED : AmountType.CUSTOM;
    this.riskLevel = getRiskType(RISK_RATIO_TO_SLIDER_STEP[this.growAccount.projects_percentage]);
    this.amountForm = this.initializeForm(this.project);

    this.updatePercentageOfPortfolio();
    this.amountControl.valueChanges
      .pipe(
        tap(() => this.updatePercentageOfPortfolio()),
        debounceTime(500),
        takeUntil(this.onDestroy$)
      )
      .subscribe((value) => {
        this.handleCustomAmountChangeDebounced(value);
      });
  }

  initializeForm(project: GrowProject) {
    const form = this.fb.group({
      amount: [
        this.hasRecommendedAmount ? project.account_specific.recommended_additional_amount : '',
        [
          Validators.required,
          Validators.min(project.minimum_funding as number),
          Validators.max(project.account_specific.maximum_additional_amount),
          InyovaGrowValidator.validateAmountStep(project.increment_step, project.minimum_funding as number)
        ]
      ],
      riskAgreed: [this.hasRecommendedAmount, [Validators.requiredTrue]]
    });
    if (this.selectedType === AmountType.RECOMMENDED) {
      form.get('riskAgreed').clearValidators();
    }
    return form;
  }

  onToggleInfo() {
    this.showAmountInfo = !this.showAmountInfo;
  }

  onOptionChange(selectedType: AmountType) {
    this.selectedType = selectedType;
    if (selectedType === AmountType.CUSTOM) {
      this.amountEditMode = true;
      this.riskAgreedControl.addValidators(Validators.requiredTrue);
      this.riskAgreedControl.setValue(false);
      this.riskAgreedControl.updateValueAndValidity();
    } else {
      this.riskAgreedControl.clearValidators();
      this.riskAgreedControl.updateValueAndValidity();
      this.amountControl.setValue(this.project.account_specific.recommended_additional_amount);
      this.amountControl.updateValueAndValidity();
    }
  }

  handleCustomAmountChangeDebounced(value: number): void {
    if (value > this.project.account_specific.maximum_additional_amount) {
      this.trackingService.trackActivity(
        `[Input] Inyova grow investment: custom investment amount of ${value} exceeds maximum amount of ${this.project.account_specific.maximum_additional_amount}`
      );
    }
  }

  updatePercentageOfPortfolio(): void {
    this.percentageOfPortfolio = getPercentageOfPortfolio(this.growAccount, this.amountControl.value, this.project.currency);
    if (this.selectedType === AmountType.CUSTOM) {
      this.riskAgreedControl.setValue(false);
    }
  }

  onAccept() {
    this.riskAgreedControl.setValue(!this.riskAgreedControl.value);
    this.riskAgreedControl.updateValueAndValidity();
  }

  onSubmit() {
    if (this.amountForm.invalid) {
      return;
    }
    this.submitAmount.emit({ amount: this.amountControl.value });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
