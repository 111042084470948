// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h1 {
  font-family: var(--font-secondary);
  font-weight: bold;
  line-height: 45px;
  margin: 0;
  text-align: center;
}

.bonds-info__flag {
  position: relative;
  margin-right: 8px;
  width: 35px;
  height: 25px;
  display: inline-block;
}
.bonds-info__flag img {
  width: 35px;
}
.bonds-info__flag ion-icon {
  color: var(--ion-color-success);
  position: absolute;
  bottom: -14px;
  right: -8px;
  width: 24px;
  height: 24px;
}

.bonds-info__header {
  margin: 16px;
}

.bonds-info__top {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bonds-info__bottom {
  font-weight: 700;
  width: 100%;
  text-align: center;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
