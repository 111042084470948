import { StrategyBondsInfoComponent } from './strategy-bonds-info/strategy-bonds-info.component';
import { CompanyTopicComponent } from './strategy-company-topic/strategy-company-topic.component';
import { DepositInfoComponent } from './strategy-deposit-info/strategy-deposit-info.component';
import { ImpactInfoComponent } from './strategy-impact-info/strategy-impact-info.component';

export const components = [CompanyTopicComponent, ImpactInfoComponent, StrategyBondsInfoComponent, DepositInfoComponent];

export { CompanyTopicComponent } from './strategy-company-topic/strategy-company-topic.component';
export { ImpactInfoComponent } from './strategy-impact-info/strategy-impact-info.component';
export { StrategyBondsInfoComponent } from './strategy-bonds-info/strategy-bonds-info.component';
export { DepositInfoComponent } from './strategy-deposit-info/strategy-deposit-info.component';
