import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Browser } from '@capacitor/browser';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as fromStrategy from '../../../strategy/strategy.reducers';

import { StatusBarStylingService } from '@app/shared/services/status-bar-styling.service';
import { COMPANY_SECTOR_API_KEY } from 'src/app/app.constants';
import { CompanyDetailData, CompanyDetailDataTopic } from 'src/app/shared/models/Strategy';

import { CompanyTopicComponent } from '../../../strategy/modals/strategy-company-topic/strategy-company-topic.component';
import { State } from '../../models/State';
import { TrackingService } from '../../services/tracking.service';

@Component({
  selector: 'app-company-detail',
  templateUrl: './company-detail.component.html',
  styleUrls: ['./company-detail.component.scss']
})
export class CompanyDetailComponent implements OnDestroy {
  companyDetail: CompanyDetailData['company'];
  isLoading: boolean;
  isEve2 = false;
  protected readonly onDestroy$ = new Subject<void>();
  pageURL: string;

  constructor(
    private router: Router,
    private store: Store<State>,
    private modalController: ModalController,
    private trackingService: TrackingService,
    private translateService: TranslateService,
    private statusBarStyling: StatusBarStylingService
  ) {
    this.store.pipe(select(fromStrategy.selectCompanyDetail), takeUntil(this.onDestroy$)).subscribe((response) => {
      this.companyDetail = response;
    });
    this.store.pipe(select(fromStrategy.selectCompanyDetailLoading), takeUntil(this.onDestroy$)).subscribe((value) => {
      this.isLoading = value;
    });
    this.pageURL = router.url;
  }

  getCompanySector(company: CompanyDetailData['company']): string {
    if (!COMPANY_SECTOR_API_KEY[company.sector]) return '';
    return `STRATEGY.stocks.description.sector${COMPANY_SECTOR_API_KEY[company.sector]}`;
  }

  getCompanyCoverImage(company: CompanyDetailData['company']): { backgroundImage: string } {
    return {
      backgroundImage: company.img ? `url('${company.img}')` : ''
    };
  }

  getStabilityPositionObject(): CompanyDetailDataTopic {
    return {
      desc: `<p>${this.translateService.instant('STRATEGY.stocks.modal.stability.p1')}</p></br><p>${this.translateService.instant(
        'STRATEGY.stocks.modal.stability.p2'
      )}</p>`,
      icon: '/assets/icon/stability.svg',
      id: 0,
      name: this.translateService.instant('STRATEGY.stocks.modal.stability.title'),
      score: 0
    };
  }

  dismiss() {
    this.modalController.dismiss({
      dismissed: true
    });
  }

  openCompanyDetail(url: string) {
    this.trackingService.trackActivity('[Link] Company URL');
    Browser.open({ url });
  }

  async openTopicDetail(companyTopic: CompanyDetailDataTopic) {
    this.statusBarStyling.setBackgroundColor('dialog');
    const modal = await this.modalController.create({
      component: CompanyTopicComponent,
      componentProps: {
        companyTopic
      },
      cssClass: 'app-fullscreen',
      presentingElement: await this.modalController.getTop()
    });
    void modal.onDidDismiss().then(() => {
      this.statusBarStyling.setBackgroundColor('dialog');
    });
    return modal.present();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
