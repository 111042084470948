import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

import { COMPANY_SECTOR_API_KEY } from '@app/app.constants';
import { StockCompany, StockCompanyTopic } from '@shared/models/Home';
import { StatusBarStylingService } from '@shared/services/status-bar-styling.service';
import { CompanyTopicComponent } from '@strategy/modals';

@Component({
  selector: 'app-stock-company-detail',
  templateUrl: './company-detail.component.html',
  styleUrls: ['./company-detail.component.scss']
})
export class StockCompanyDetailComponent {
  @Input() companyDetail: StockCompany;
  isLoading = false;
  isEve2 = true;
  pageURL: string;

  constructor(
    private router: Router,
    private modalController: ModalController,
    private translateService: TranslateService,
    private statusBarStyling: StatusBarStylingService
  ) {
    this.pageURL = this.router.url;
  }

  getCompanySector(company: StockCompany): string {
    if (!COMPANY_SECTOR_API_KEY[company.sector]) return '';
    return `STRATEGY.stocks.description.sector${COMPANY_SECTOR_API_KEY[company.sector]}`;
  }

  getCompanyCoverImage(company: StockCompany): { backgroundImage: string } {
    return {
      backgroundImage: company.img ? `url('${company.img}')` : ''
    };
  }

  getStabilityPositionObject(): StockCompanyTopic {
    return {
      desc: `<p>${this.translateService.instant('STRATEGY.stocks.modal.stability.p1')}</p></br><p>${this.translateService.instant(
        'STRATEGY.stocks.modal.stability.p2'
      )}</p>`,
      icon: '/assets/icon/stability.svg',
      name: this.translateService.instant('STRATEGY.stocks.modal.stability.title')
    };
  }

  dismiss() {
    this.modalController.dismiss({
      dismissed: true
    });
  }

  async openTopicDetail(companyTopic: StockCompanyTopic) {
    this.statusBarStyling.setBackgroundColor('dialog');
    const modal = await this.modalController.create({
      component: CompanyTopicComponent,
      componentProps: {
        companyTopic
      },
      cssClass: 'app-fullscreen',
      presentingElement: await this.modalController.getTop()
    });
    void modal.onDidDismiss().then(() => {
      this.statusBarStyling.setBackgroundColor('dialog');
    });
    return modal.present();
  }
}
