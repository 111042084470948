// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-content {
  --padding-start: var(--spacing-6);
  --padding-end: var(--spacing-6);
  --padding-bottom: var(--spacing-8);
}

app-inyova-grow-project-preview {
  display: block;
  margin-bottom: var(--spacing-8);
}

.investment-done .ds-heading-2 {
  margin: 0 0 var(--spacing-3) 0;
}

.ds-list {
  margin-bottom: var(--spacing-8);
}

.inyova-grow-figures {
  margin-bottom: var(--spacing-8);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
