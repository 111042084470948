import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-impact-project-detail',
  templateUrl: './inyova-grow-impact-project-detail.component.html',
  styleUrls: ['./inyova-grow-impact-project-detail.component.scss']
})
export class InyovaGrowImpactProjectModalComponent {
  @Input() title: string;
  @Input() image: string;
  @Input() impactDescription: string;

  constructor(
    private modalController: ModalController,
    private translateService: TranslateService
  ) {}

  onDismiss() {
    this.modalController.dismiss();
  }
}
