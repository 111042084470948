import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

import { noop } from 'rxjs';

import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-signup-redirect',
  templateUrl: './signup-redirect.component.html',
  styleUrls: ['./signup-redirect.component.scss']
})
export class SignupRedirectComponent {
  constructor(
    private modalController: ModalController,
    private translateService: TranslateService
  ) {}

  dismiss(): void {
    this.modalController.dismiss({ dismissed: true }).finally(noop);
  }

  getSignupLink(): string {
    const lang = {
      en: 'create-profile',
      fr: 'creer-profil',
      de: 'profil-erstellen'
    };
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return lang[this.translateService.currentLang.slice(0, 2)];
  }

  redirectToSignup(): void {
    this.dismiss();
    // Default redirect to CH app for Sign up
    window.open(`${environment.customersApp.ch}/${this.getSignupLink()}`, '_system');
  }
}
