import { AccountFeatureFlags, CustomerAccount, InvestmentDetails, PortfolioAllocationV2, WithdrawalAllocation } from '@inyova/models';

import { Action, createReducer, createSelector, on } from '@ngrx/store';
import * as StrategyActions from './strategy.actions';
import * as AccountActions from '../account/account.actions';
import { selectAccountFeatureFlag, selectCurrentAccount, selectCustomerAndCurrentAccount } from '@account/account.reducers';
import { selectAccountsHomepage, selectTechnicalIssuesReporting } from '@home/reducers';

import { getChosenRiskLevel } from '@app/home/home.utils';
import { State } from '@shared/models/State';
import {
  CompanyDetailData,
  CriteriumData,
  CustomerStockCompany,
  InvestmentData,
  PortfolioAllocation,
  PortfolioStocks,
  RiskSurveyQuestion,
  StocksAllocation,
  TopicData
} from '@shared/models/Strategy';

import { transformStocksAllocationData, transformStocksAllocationShortlist } from './strategy.utlis';
import { RETIREMENT_AGE } from '../app.constants';

export const STRATEGY_REDUCER_FEATURE_KEY = 'strategy';

export const initialState: StrategyState = {
  investments: {
    currency: 'CHF',
    total: 0,
    stocks: 0,
    bonds: 0,
    monthly_investment: 0,
    stock_split: 0,
    live_stock_split: 0,
    current_amount: 0
  },
  investmentDetails: {
    stock_split: 0,
    chosen_at: '',
    strategy_change_available: false,
    next_strategy_change_at: null
  },
  criteria: [],
  topics: [],
  portfolioStocks: {
    blacklist: [],
    female_leadership: 0,
    wishlist: [],
    yova_stocks: []
  },
  companyDetailData: {
    company: {
      additional_topics: [],
      bad_bullet_points: [],
      based: '',
      criteria_dismatch: [],
      currency: '',
      desc: '',
      extra_desc: '',
      good_bullet_points: [],
      id: 0,
      img: '',
      is_balance: false,
      is_blacklist: false,
      is_wishlist: false,
      is_yellow: false,
      link_string: '',
      link: '',
      logo: '',
      name: '',
      sector: '',
      topics: [
        {
          desc: '',
          icon: '',
          id: 0,
          name: '',
          score: 0
        }
      ],
      stock_ratio: 0,
      yova_match: 0
    },
    loading: false
  },
  loading: false,
  riskQuestions: [],
  editModeStatus: false,
  stocksAllocation: {
    currentTab: 'sector',
    selectedIndex: null,
    filteredPercentage: [],
    filteredShortlist: [],
    currencyPercentages: [],
    sectorPercentages: []
  },
  portfolioAllocation: null,
  portfolioAllocationV2: null
};

const strategyReducer = createReducer(
  initialState,
  on(StrategyActions.getCompanyDetail, (state) => ({
    ...state,
    companyDetailData: {
      company: initialState.companyDetailData.company,
      loading: true
    }
  })),
  on(StrategyActions.getCustomerStocks, (state) => ({
    ...state,
    loading: true
  })),
  on(StrategyActions.setInvestmentData, (state, action) => ({
    ...state,
    investments: action
  })),
  on(StrategyActions.getInvestmentDetailsSuccess, (state, { investmentDetails }) => {
    return {
      ...state,
      investmentDetails
    };
  }),
  on(AccountActions.updateSpSuccess, (state, action) => ({
    ...state,
    investments: {
      ...state.investments,
      monthly_investment: action.data
    }
  })),
  on(StrategyActions.setCriteriaData, (state, action) => ({
    ...state,
    criteria: action.data
  })),
  on(StrategyActions.setTopicsData, (state, action) => ({
    ...state,
    topics: action.data
  })),
  on(StrategyActions.setCustomerStocks, (state, action) => ({
    ...state,
    portfolioStocks: action.data,
    loading: false
  })),
  on(StrategyActions.setCompanyDetail, (state, action) => ({
    ...state,
    companyDetailData: {
      company: { ...action },
      loading: false
    }
  })),
  on(StrategyActions.setRiskQuestions, (state, action) => ({
    ...state,
    riskQuestions: action.riskQuestions
  })),
  on(StrategyActions.setStrategyEditModeStatus, (state, action) => ({
    ...state,
    editModeStatus: action.editModeStatus
  })),
  on(StrategyActions.getStocksAllocationSuccess, (state, action) => {
    const sectorPercentages = transformStocksAllocationData(action.data.sector_percentages);
    const currencyPercentages = transformStocksAllocationData(action.data.currency_percentages);

    return {
      ...state,
      stocksAllocation: {
        sectorPercentages,
        currencyPercentages,
        filteredPercentage: sectorPercentages,
        filteredShortlist: transformStocksAllocationShortlist(sectorPercentages),
        currentTab: 'sector' as const,
        selectedIndex: null
      }
    };
  }),
  on(StrategyActions.updateStocksAllocation, (state, action) => ({
    ...state,
    stocksAllocation: {
      ...state.stocksAllocation,
      currentTab: action.currentTab,
      selectedIndex: action.selectedIndex,
      filteredPercentage: action.currentTab === 'sector' ? state.stocksAllocation.sectorPercentages : state.stocksAllocation.currencyPercentages
    }
  })),
  on(StrategyActions.getPortfolioAllocationSuccess, (state, { data, hasFeatureFlag }) => ({
    ...state,
    portfolioAllocation: !hasFeatureFlag ? (data as PortfolioAllocation) : null,
    portfolioAllocationV2: hasFeatureFlag ? (data as PortfolioAllocationV2) : null
  })),
  on(StrategyActions.getPortfolioAllocationFail, (state) => ({
    ...state,
    portfolioAllocation: null,
    portfolioAllocationV2: null
  })),
  // NgRx store clean up after logout
  on(AccountActions.customerLogout, () => ({ ...initialState })),
  on(AccountActions.customerLogoutAndUnpairDevice, () => ({ ...initialState }))
);

export function reducer(state: StrategyState | undefined, action: Action) {
  return strategyReducer(state, action);
}

export interface StrategyState {
  investments: InvestmentData;
  investmentDetails: InvestmentDetails;
  criteria: CriteriumData[];
  topics: TopicData[];
  portfolioStocks: PortfolioStocks;
  companyDetailData: CompanyDetailData;
  loading: boolean;
  riskQuestions: RiskSurveyQuestion[];
  editModeStatus: boolean;
  stocksAllocation: StocksAllocation;
  portfolioAllocation: PortfolioAllocation | null;
  portfolioAllocationV2: PortfolioAllocationV2 | null;
}

export interface Strategy3AState {
  strategy3A: Strategy3AState;
}

/*
 * SELECTORS
 * */
export const selectFeature = (state: State) => state.strategy;
// Strategy reducer selectors
export const selectCustomerInvestment = createSelector(selectFeature, (state: StrategyState) => state.investments);

export const selectInvestmentDetails = createSelector(selectFeature, (state: StrategyState) => state.investmentDetails);

export const selectHomeStrategyDetails = createSelector(
  selectAccountFeatureFlag(AccountFeatureFlags.EVE2),
  selectCurrentAccount,
  selectCustomerInvestment,
  selectInvestmentDetails,
  (hasFeatureFlag: boolean, account: CustomerAccount, customerInvestments: InvestmentData, investmentDetails: InvestmentDetails) => {
    return {
      riskLevel: getChosenRiskLevel(hasFeatureFlag ? investmentDetails.stock_split : customerInvestments.stock_split),
      nextStrategyChangeAt: hasFeatureFlag ? investmentDetails.next_strategy_change_at : account.next_strategy_change_on
    };
  }
);

export const selectCustomerCriteria = createSelector(selectFeature, (state: StrategyState) => state.criteria);

export const selectStrategyLoading = createSelector(selectFeature, (state: StrategyState) => state.loading);

export const selectTopicsData = createSelector(selectFeature, (state: StrategyState) => state.topics);

export const selectTopicsHandprint = createSelector(selectTopicsData, (state: TopicData[]) => {
  return state.filter((obj) => obj.print_type === 'Handprint');
});

export const selectTopicsFootprint = createSelector(selectTopicsData, (state: TopicData[]) => {
  return state.filter((obj) => obj.print_type === 'Footprint');
});

export const selectTopicsBalance = createSelector(selectTopicsData, (state: TopicData[]) => {
  return state.filter((obj) => obj.print_type !== 'Footprint' && obj.print_type !== 'Handprint' && obj.id !== 32);
});

export const selectCustomerStocks = createSelector(selectFeature, (state: StrategyState) => state.portfolioStocks);

export const selectPortfolioStocks = createSelector(selectCustomerStocks, (state: PortfolioStocks) => {
  return [...state.yova_stocks].sort((a, b) => b.topics.length - a.topics.length);
});

export const selectPortfolioFemaleLeadership = createSelector(selectCustomerStocks, (state: PortfolioStocks) => {
  const leadership = state.female_leadership / 100;
  return Math.round(leadership);
});

export const selectPortfolioWishes = createSelector(selectCustomerStocks, (state: PortfolioStocks) => {
  if (!state.wishlist) {
    return [];
  }
  return state.wishlist.filter((wish) => wish.is_included !== false).sort((a, b) => b.topics.length - a.topics.length);
});

export const selectPortfolioCompaniesSomeZeroValues = createSelector(
  selectPortfolioStocks,
  selectPortfolioWishes,
  (stocks: CustomerStockCompany[], wishes: CustomerStockCompany[]) => {
    return [...stocks, ...wishes].some((company) => company.stock_ratio === 0);
  }
);

export const selectCompanyDetail = createSelector(selectFeature, (state: StrategyState) => state.companyDetailData.company);

export const selectCompanyDetailLoading = createSelector(selectFeature, (state: StrategyState) => state.companyDetailData.loading);

export const selectRetirementDetails = createSelector(selectFeature, (state: StrategyState) => {
  const riskInvestmentHorizon = state.riskQuestions.filter((item) => item.subtype === 'investment_horizon');
  if (!riskInvestmentHorizon || riskInvestmentHorizon.length === 0) {
    return null;
  }
  const currentYear = new Date().getFullYear();
  const answerDOB = riskInvestmentHorizon[0].survey_customer_answer.answer as string;
  const savedDOB = answerDOB.split('/');
  const difference = currentYear - Number(savedDOB[0]);
  const timeToRetirement = RETIREMENT_AGE - difference;
  const retirementYear = currentYear + timeToRetirement;
  return { timeToRetirement, retirementYear };
});

export const selectStrategyEditModeStatus = createSelector(selectFeature, (state: StrategyState) => state.editModeStatus);
export const selectStocksAllocation = createSelector(selectFeature, (state: StrategyState) => state.stocksAllocation);
export const selectRandomCompanies = createSelector(
  selectStocksAllocation,
  selectPortfolioStocks,
  selectPortfolioWishes,
  (stocksAllocation: StocksAllocation, portfolioStocks: CustomerStockCompany[], portfolioWishes: CustomerStockCompany[]) => {
    if (!stocksAllocation || stocksAllocation?.selectedIndex === null) {
      return [];
    }

    const randomCompanies: CustomerStockCompany[] = [];
    const { currentTab, selectedIndex } = stocksAllocation;

    [...portfolioWishes, ...portfolioStocks].forEach((item) => {
      if (randomCompanies.length < 3 && item[currentTab] === stocksAllocation.filteredPercentage[selectedIndex].key) {
        randomCompanies.push(item);
      }
    });

    return randomCompanies;
  }
);

export const selectPortfolioAllocation = createSelector(selectFeature, (state: StrategyState) => state.portfolioAllocation);
export const selectPortfolioAllocationV2 = createSelector(selectFeature, (state: StrategyState) => state.portfolioAllocationV2);

export const selectWithdrawalAllocation = createSelector(
  selectAccountFeatureFlag(AccountFeatureFlags.EVE2),
  selectPortfolioAllocation,
  selectPortfolioAllocationV2,
  selectCurrentAccount,
  (hasFeatureFlag, portfolioAllocation, portfolioAllocationV2, currentAccount) => {
    if (portfolioAllocation === null && portfolioAllocationV2 === null) return {} as unknown as WithdrawalAllocation;

    const minInvestment = currentAccount.minimum_investment;
    const totalWithCash = hasFeatureFlag ? portfolioAllocationV2.total_amount : portfolioAllocation.total_value;
    const cashValue = hasFeatureFlag ? portfolioAllocationV2.cash_amount : portfolioAllocation.cash_value;

    return {
      initial: {
        percentage: Number((minInvestment / totalWithCash).toFixed(2)),
        value: minInvestment
      },
      portfolio: {
        percentage: Number(((totalWithCash - minInvestment - cashValue) / totalWithCash).toFixed(2)),
        value: Number((totalWithCash - minInvestment - cashValue).toFixed(2))
      },
      cash: {
        percentage: Number((cashValue / totalWithCash).toFixed(2)),
        value: cashValue
      },
      totalAmount: totalWithCash
    };
  }
);

export const selectAccountInvested = createSelector(
  selectAccountsHomepage,
  selectCustomerAndCurrentAccount,
  selectTechnicalIssuesReporting,
  (accountsHomepage, { currentAccount, customer }, technicalIssues) => {
    const accountData = accountsHomepage[currentAccount.id];
    return !!(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      (
        accountData?.graphData?.initialised &&
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        accountData?.endValues?.initialised &&
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        accountData?.graphData?.graphData.length &&
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        !((technicalIssues[`${customer.app_location}${currentAccount.kind}`] as boolean) || accountData?.graphData?.hasError)
      )
    );
  }
);
