import { InyovaGrowAccount } from '@shared/models/Grow';

export const getPercentageOfPortfolio = (growAccount: InyovaGrowAccount, amountToInvest: number, projectCurrency: string): number => {
  if (growAccount.portfolio_value === 0) return 0;
  const isDifferentCurrency = projectCurrency.toLowerCase() !== growAccount.currency.toLowerCase();

  const amountInPortfolio = isDifferentCurrency ? amountToInvest * growAccount.eur_exchange_rate : amountToInvest;

  return ((amountInPortfolio / growAccount.portfolio_value) * 100) / 100;
};
