import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { Subject } from 'rxjs';

@Component({
  selector: 'app-strategy-deposit-info',
  templateUrl: './strategy-deposit-info.component.html',
  styleUrls: ['./strategy-deposit-info.component.scss']
})
export class DepositInfoComponent {
  @Input() key: string;
  protected readonly onDestroy$ = new Subject<void>();

  constructor(private modalController: ModalController) {}

  dismiss() {
    this.modalController.dismiss({
      dismissed: true
    });
  }
}
