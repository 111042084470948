import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InvestmentDetails, PortfolioAllocationV2, PortfolioAllocationResponse } from '@inyova/models';

import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as fromRoot from '@app/app.reducers';

import { Company, CriteriumData, InvestmentData, PortfolioStocks, RiskSurveyQuestion, StockAllocationAPI, TopicData } from '@shared/models/Strategy';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StrategyResources {
  private readonly URL: string;
  private readonly URL_V4: string;
  private accountID: string;

  constructor(
    private http: HttpClient,
    private store: Store
  ) {
    this.store.pipe(select(fromRoot.selectSelectedAccount)).subscribe((account) => (this.accountID = account?.id));
    this.URL = `${environment.apiUrl}`;
    this.URL_V4 = `${environment.apiUrlV4}`;
  }

  getInvestmentData(): Observable<{ customer: InvestmentData }> {
    return this.http.get<{ customer: InvestmentData }>(`${this.URL}/accounts/${this.accountID}/profiling`);
  }

  getInvestmentDetails(): Observable<InvestmentDetails> {
    return this.http
      .get<{ strategy: InvestmentDetails }>(`${this.URL_V4}/trading_prep/accounts/${this.accountID}/strategy`)
      .pipe(map((res: { strategy: InvestmentDetails }) => res.strategy));
  }

  getTopicsData(): Observable<{ customer: { topics: TopicData[] }; topics: TopicData[] }> {
    return this.http.get<{ customer: { topics: TopicData[] }; topics: TopicData[] }>(`${environment.apiUrl}/accounts/${this.accountID}/topics`);
  }

  getCriteriaData(): Observable<{ customer: { criteria: CriteriumData[] }; criteria: CriteriumData[] }> {
    return this.http.get<{ customer: { criteria: CriteriumData[] }; criteria: CriteriumData[] }>(`${this.URL}/accounts/${this.accountID}/criteria`);
  }

  getCustomerStocks(): Observable<PortfolioStocks> {
    return this.http.get<PortfolioStocks>(`${this.URL}/accounts/${this.accountID}/strategy`);
  }

  getCompanyDetail(companyId: number): Observable<{ company: Company }> {
    return this.http.get<{ company: Company }>(`${this.URL}/accounts/${this.accountID}/strategy/company/${companyId}`);
  }

  getRiskQuestions(): Observable<{ survey_questions: RiskSurveyQuestion[] }> {
    return this.http.get<{ survey_questions: RiskSurveyQuestion[] }>(`${environment.apiUrl}/accounts/${this.accountID}/survey/risk`);
  }

  getStrategyEditMode(): Observable<{ disabled: boolean }> {
    return this.http.get<{ disabled: boolean }>(`${environment.apiUrl}/accounts/${this.accountID}/edit-mode`);
  }

  getStockAllocation(): Observable<{ data: { id: string; type: string }; meta: StockAllocationAPI }> {
    return this.http.get<{ data: { id: string; type: string }; meta: StockAllocationAPI }>(
      `${environment.apiUrlV3}/target_portfolios/${this.accountID}?sector_percentages=true&currency_percentages=true`
    );
  }

  getPortfolioAllocation(): Observable<PortfolioAllocationResponse> {
    return this.http.get<PortfolioAllocationResponse>(`${environment.apiUrlV3}/portfolio_allocations/${this.accountID}`);
  }

  getPortfolioAllocationV2(): Observable<PortfolioAllocationV2> {
    return this.http
      .get<{ portfolio_allocation: PortfolioAllocationV2 }>(`${environment.apiUrlV4}/reporting/accounts/${this.accountID}/portfolio_allocation`)
      .pipe(map((res) => res.portfolio_allocation));
  }
}
