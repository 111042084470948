// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-content {
  --padding-top: var(--ion-safe-area-top);
}

.campaigns-wrapper {
  margin: 27px 0 45px;
}
.campaigns-wrapper .card-wrapper {
  margin-top: 13px;
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  overflow: hidden;
  background-color: var(--ion-color-light-shade);
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 210px;
}
.campaigns-wrapper .card-wrapper.active {
  background-color: var(--ion-color-primary);
}
.campaigns-wrapper .card-wrapper img {
  width: inherit;
  height: inherit;
  object-fit: cover;
}
.campaigns-wrapper .card-wrapper .invite-friend,
.campaigns-wrapper .card-wrapper button {
  position: absolute;
  bottom: 20px;
  left: 0;
  margin: 0 16px;
  text-align: center;
  color: var(--ion-color-white);
  width: calc(100% - 32px);
}
.campaigns-wrapper .card-wrapper .invite-friend {
  font-size: 14px;
}
.campaigns-wrapper .card-wrapper label {
  position: absolute;
  top: 0;
  left: 0;
  padding: 4px 8px;
  border-radius: 4px 0 0 0;
  background-color: var(--ion-color-primary);
  color: var(--ion-color-white);
  font-size: 12px;
  line-height: 16px;
}
.campaigns-wrapper .card-wrapper label.ongoing {
  background-color: var(--ion-color-red);
}
.campaigns-wrapper .card-wrapper h2 {
  font-family: var(--font-secondary);
  font-weight: 600;
  font-size: 26px;
  line-height: 36px;
  text-align: center;
  letter-spacing: -0.005em;
  color: var(--ion-color-primary);
  transform: rotate(-6deg);
  background: var(--ion-color-success);
  padding: 5px;
  margin: 24px 0 18px 0;
  width: calc(100% + 20px);
  left: -10px;
  position: relative;
}
.campaigns-wrapper .card-wrapper h2.long {
  font-size: 20px;
}
.campaigns-wrapper .card-wrapper h2.narrow {
  font-size: 18px;
}
.campaigns-wrapper .card-wrapper h2.liked {
  margin-top: 50px;
}
.campaigns-wrapper .card-wrapper ion-icon[name=hearts] {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 20px;
  padding: 6px;
  background-color: var(--ion-color-primary);
  color: var(--ion-color-white);
  border-radius: 0 4px 0 0;
}

.impact-header {
  margin: 24px 0 48px;
}

.impact-header h1,
.campaigns-wrapper h1 {
  font-family: var(--font-secondary);
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 0;
}
.impact-header p,
.campaigns-wrapper p {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.005em;
  color: var(--ion-color-new-grey);
}

.rights-intro {
  margin: 16px 0;
  font-family: var(--font-primary);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.rights-intro .title {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

.shareholder-rights {
  display: flex;
  gap: 8px;
  padding: 16px 12px;
  margin: 8px 0;
  background-color: var(--ion-color-white);
  font-family: var(--font-primary);
  font-size: 12px;
  text-align: left;
  letter-spacing: 0em;
}
.shareholder-rights img {
  width: 64px;
  height: 64px;
}
.shareholder-rights .title {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
}

ion-skeleton-text {
  margin-bottom: 12px;
}
ion-skeleton-text.impact {
  height: 200px;
}

.supporters {
  display: flex;
  color: var(--ion-color-success);
  margin: 0 24px;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
}
@media screen and (max-width: 413px) {
  .supporters {
    margin: 0 12px;
  }
}
.supporters.narrow {
  margin: 0 12px;
}
.supporters.narrow span {
  font-size: 12px;
}
.supporters.narrow .numbers {
  font-size: 32px;
}
.supporters .numbers {
  font-family: var(--font-secondary);
  color: inherit;
  font-family: var;
  font-weight: 600;
  font-size: 40px;
  line-height: 40px;
  letter-spacing: -0.01em;
  margin-right: 10px;
}
.supporters span {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.005em;
  text-align: left;
}

.grow-impact {
  margin: var(--spacing-10) 0;
  display: block;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
