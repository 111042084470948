import { Component, Input } from '@angular/core';
import { Browser } from '@capacitor/browser';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

import { Subject } from 'rxjs';

import { TrackingService } from '@shared/services/tracking.service';

@Component({
  selector: 'app-strategy-impact-info',
  templateUrl: './strategy-impact-info.component.html',
  styleUrls: ['./strategy-impact-info.component.scss']
})
export class ImpactInfoComponent {
  @Input() impact: string;
  protected readonly onDestroy$ = new Subject<void>();

  constructor(
    private modalController: ModalController,
    private translateService: TranslateService,
    private trackingService: TrackingService
  ) {}

  dismiss(): void {
    void this.modalController.dismiss({
      dismissed: true
    });
  }

  openLearnMore(): void {
    const url = this.translateService.instant('STRATEGY.impact.whitepaper.url');
    this.trackingService.trackActivity('[Link] Whitepaper');
    void Browser.open({ url });
  }
}
