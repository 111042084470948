import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { AmplitudeService, getRemainingTime } from '@inyova/inyova-shared';
import { PortfolioAllocation, PortfolioAllocationV2 } from '@inyova/models';

import { TrackingService } from '@app/shared/services/tracking.service';

@Component({
  selector: 'app-home-strategy',
  styleUrls: ['./home-strategy.component.scss'],
  templateUrl: './home-strategy.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeStrategyComponent implements OnInit {
  @Input() portfolioAllocation: PortfolioAllocation;
  @Input() portfolioAllocationV2: PortfolioAllocationV2;
  @Input() currency: string;
  @Input() kind: '3b' | '3a' | 'kid' | 'ci' | 'interest' | 'grow';
  @Input() homeStrategyDetails: { riskLevel: string; nextStrategyChangeAt: string };

  showElements = {
    strategyTitle: false,
    strategy: false,
    rebalancing: false,
    diversification: false,
    benchmark: false,
    review: false,
    strategyChange: false
  };

  remainingTime;

  constructor(
    private trackingService: TrackingService,
    private amplitudeService: AmplitudeService
  ) {}

  ngOnInit(): void {
    this.remainingTime = getRemainingTime(this.homeStrategyDetails.nextStrategyChangeAt);
  }

  getStrategyChangeRemaingDays(): string {
    const defaultKey = 'PERFORMANCE.contentSwitcher.strategy.portfolioDetails.strategyChange';
    const { months, days, hours } = this.remainingTime;

    if (months > 1) {
      return `${defaultKey}.multipleMonths`;
    } else if (months === 1 && (days >= 30 || days >= 31)) {
      return `${defaultKey}.singleMonth`;
    } else if (days > 1) {
      return `${defaultKey}.multipleDays`;
    } else if (days === 1 || hours >= 1) {
      return `${defaultKey}.singleDay`;
    }

    return `${defaultKey}.now`;
  }

  getDaysKey() {
    const days = this.getDays();
    if (days > 1) {
      return 'PERFORMANCE.contentSwitcher.strategy.portfolioDetails.nextPortfolioReview.multipleDays';
    }

    if (days === 0) {
      return 'PERFORMANCE.contentSwitcher.strategy.portfolioDetails.nextPortfolioReview.today';
    }

    return 'PERFORMANCE.contentSwitcher.strategy.portfolioDetails.nextPortfolioReview.singleDay';
  }

  getDays(): number {
    const day = new Date().getDay();
    return (3 - day + 7) % 7;
  }

  toggleInfo(element: string) {
    this.showElements[element] = !this.showElements[element];
  }

  onTrackFeedback(message) {
    this.trackingService.trackActivity(message);
  }

  amplitudeTrack(eventName: string, data?: any) {
    this.amplitudeService.trackEvent(eventName, data);
  }
}
