import { Component, Input, OnInit } from '@angular/core';
import { AmplitudeService } from '@inyova/inyova-shared';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as InyovaGrowActions from '@grow/inyova-grow.actions';
import * as fromGrow from '@grow/inyova-grow.reducers';

import { InyovaGrowAccount } from '@app/shared/models/Grow';
import { ClipboardService } from '@app/shared/services/clipboard.service';
import { ToastService } from '@shared/services/toast.service';
import { TrackingService } from '@shared/services/tracking.service';

@Component({
  selector: 'app-inyova-grow-deposit-modal',
  templateUrl: './inyova-grow-deposit-modal.component.html',
  styleUrls: ['./inyova-grow-deposit-modal.component.scss']
})
export class InyovaGrowDepositModalComponent implements OnInit {
  @Input() growAccount: InyovaGrowAccount;
  growAccount$: Observable<InyovaGrowAccount>;

  constructor(
    private modalController: ModalController,
    private trackingService: TrackingService,
    private translateService: TranslateService,
    private toastService: ToastService,
    private clipboardService: ClipboardService,
    private store: Store,
    private amplitudeService: AmplitudeService
  ) {}

  ngOnInit() {
    this.trackingService.trackActivity('[Grow deposit modal]: Opened');
    this.amplitudeService.trackEvent(`Deposit Funds Details Viewed`, { offering_account_type: 'grow' });
    this.store.dispatch(InyovaGrowActions.getGrowIban());
    this.growAccount$ = this.store.select(fromGrow.selectInyovaGrowAccount);
  }

  onDismiss() {
    this.trackingService.trackActivity('[Grow deposit modal]: Dismissed');
    this.modalController.dismiss();
  }

  async copyToClipboard(value) {
    if (!value) {
      return;
    }
    let copyTarget = '';
    switch (value) {
      case 'swift':
        copyTarget = this.translateService.instant('GROW.deposit.modal.swiftValue');
        break;
      case 'address':
        copyTarget = this.translateService.instant('GROW.deposit.modal.addressValue');
        break;
      default:
        copyTarget = value;
        break;
    }

    await this.clipboardService.copyToClipboard(copyTarget);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    void this.toastService.show(this.translateService.instant('SHARED.actions.copiedToClipboard'));
  }

  onCloseModal() {
    this.trackingService.trackActivity('[Grow deposit modal]: Closed');
    void this.modalController.dismiss();
  }
}
