import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { TrackingService } from '@app/shared/services/tracking.service';
import { InyovaGrowDepositModalComponent } from '@grow/modals';

@Component({
  selector: 'app-inyova-grow-deposit-fund-modal',
  templateUrl: './inyova-grow-deposit-fund-modal.component.html',
  styleUrls: ['./inyova-grow-deposit-fund-modal.component.scss']
})
export class InyovaGrowDepositFundModalComponent implements OnInit {
  constructor(
    private modalController: ModalController,
    private trackingService: TrackingService
  ) {}

  ngOnInit() {
    this.trackingService.trackActivity('[Grow fund explanation modal]: Opened');
  }

  onDismiss() {
    this.trackingService.trackActivity('[BUTTON] GROW fund modal: Dismiss');
    void this.modalController.dismiss();
  }

  async onOpenDepositModal() {
    this.trackingService.trackActivity('[BUTTON] GROW fund modal: Proceed');
    void this.modalController.dismiss();
    const modal = await this.modalController.create({
      component: InyovaGrowDepositModalComponent,
      canDismiss: true
    });
    return modal.present();
  }

  onCloseFundModal() {
    this.trackingService.trackActivity('[BUTTON] GROW fund modal: Not now');
    void this.modalController.dismiss();
  }
}
