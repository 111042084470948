// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.investment-setup-info {
  margin-bottom: var(--spacing-8);
}

.row-info {
  margin-top: var(--spacing-2);
  margin-bottom: var(--spacing-2);
}

.investment-amount-type {
  margin-bottom: var(--spacing-3);
}
.investment-amount-type .ds-rows-white {
  margin-bottom: var(--spacing-4);
  margin-top: var(--spacing-2);
}
.investment-amount-type .title {
  margin-bottom: var(--spacing-2);
}

.not-available-callout {
  margin-top: var(--spacing-1);
}

.customer-amount-form > app-ds-button {
  display: block;
  margin-top: var(--spacing-8);
}

.disclaimer {
  margin-top: var(--spacing-4);
}

.amount-with-info {
  display: flex;
  flex-direction: row;
}
.amount-with-info > :first-child {
  flex-grow: 1;
}

.review-box {
  margin-top: var(--spacing-4);
}

.investment-hints {
  margin-top: var(--spacing-4);
}
.investment-hints > p:first-child {
  margin: var(--spacing-0);
}
.investment-hints > p:last-child {
  margin-top: var(--spacing-4);
}
.investment-hints > ul {
  list-style-type: square;
  margin: var(--spacing-0);
  padding-left: var(--spacing-6);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
