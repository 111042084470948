// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h1 {
  font-family: var(--font-secondary);
  font-weight: bold;
  margin: 0 auto 46px;
  text-align: center;
}

h2 {
  font-family: var(--font-secondary);
  font-size: 16px;
  font-weight: bold;
  text-align: left;
}

ion-content {
  --padding-start: 15px;
  --padding-end: 15px;
  text-align: center;
}
ion-content ion-button {
  margin: 45px 0;
  font-weight: 700;
  width: 100%;
}

img {
  height: 85px;
  width: 85px;
}

p {
  line-height: 1.5;
  text-align: left;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
