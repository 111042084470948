import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Browser } from '@capacitor/browser';
import { AmplitudeService, getRemainingTime } from '@inyova/inyova-shared';
import { Customer, CustomerAccountItem, CustomerUnifiedAccountItem, GreennessSurveyTypes } from '@inyova/models';
import { isCustomer } from '@inyova/utils';
import { IonRouterOutlet, ModalController, PickerController, Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { TranslateService } from '@ngx-translate/core';

import { Store, select } from '@ngrx/store';
import { Observable, Subject, Subscription, noop } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import * as AccountActions from '@account/account.actions';
import * as RootActions from '@app/app.actions';
import * as HomeActions from '@home/home.actions';
import * as fromAccount from '@account/account.reducers';
import * as fromRoot from '@app/app.reducers';
import * as fromGrow from '@grow/inyova-grow.reducers';
import * as fromHome from '@home/reducers';

import { AccountGreennessModalComponent } from '@account/modals';
import {
  EXTERNAL_URLS,
  IMPACT_CAMPAIGNS_INYOVA_IDS,
  IMPACT_CAMPAIGNS_UPDATE_INYOVA_IDS,
  IonicStorageKeys,
  LocalStorageKeys
} from '@app/app.constants';
import { InyovaGrowAccount, InyovaGrowAccountStatus } from '@app/shared/models/Grow';
import { StatusBarStylingService } from '@app/shared/services/status-bar-styling.service';
import { HomeBannerModalComponent, HomePillar3aComponent, HomeSuperhumanSurveyComponent } from '@home/modals';
import { LoginType } from '@public/public.constants';
import { Notification } from '@shared/models/Account';
import {
  CrowdInvestingValues,
  ImpactMetricItem,
  ImpactStoriesItem,
  ImpactStoryDetail,
  PromoCampaignItem,
  TechnicalIssuesReporting
} from '@shared/models/Home';
import { BiometricLogin, State } from '@shared/models/State';
import { AuthService } from '@shared/services/auth.service';
import { LanguageService } from '@shared/services/language.service';
import { NotificationService } from '@shared/services/notification.service';
import { TrackingService } from '@shared/services/tracking.service';
import { NotificationResources } from '@shared/yova-api/notification.resources';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: 'home.page.html',
  styleUrls: ['home.page.scss'],
  animations: [
    trigger('notificationAnim', [
      state(
        'true',
        style({
          opacity: 1,
          transform: 'none'
        })
      ),
      state(
        'false',
        style({
          opacity: 0,
          transform: 'translateY(-20px)'
        })
      ),
      transition('* => *', animate('800ms cubic-bezier(0.25, 0.8, 0.25, 1)'))
    ])
  ]
})
export class HomePageComponent implements OnDestroy, OnInit {
  isInitialized: boolean;
  biometricLogin: BiometricLogin;
  currencyShares: string;
  customer: Customer;
  notifications: Notification[];
  notification: Notification;
  promoCampaigns$: Observable<PromoCampaignItem[]>;
  inyovaGrowAccount: InyovaGrowAccount;

  displayOddModal = false;
  impactMetrics: ImpactMetricItem[];
  impactStories: ImpactStoriesItem[];
  subscription: Subscription;
  technicalIssues: TechnicalIssuesReporting;
  crowdinvestingValues: CrowdInvestingValues;
  has3aAccount = false;
  active3aAccount: CustomerUnifiedAccountItem;

  accountsHomepage = {};
  filterValue: null | string;
  pagedStories: ImpactStoriesItem[] = [];
  stories: ImpactStoriesItem[];
  storiesLimit = 5;
  showFilter!: boolean;

  isStoriesLoading$: Observable<boolean>;

  activeLanguage: string;

  protected readonly onDestroy$ = new Subject<void>();

  constructor(
    private storage: Storage,
    private store: Store<State>,
    private router: Router,
    private route: ActivatedRoute,
    private platform: Platform,
    private authService: AuthService,
    private pickerController: PickerController,
    private trackingService: TrackingService,
    private translateService: TranslateService,
    private modalController: ModalController,
    private notificationResources: NotificationResources,
    private notificationService: NotificationService,
    private languageService: LanguageService,
    private routerOutlet: IonRouterOutlet,
    private statusBarStyling: StatusBarStylingService,
    private amplitudeService: AmplitudeService
  ) {
    this.store.dispatch(HomeActions.getCustomerNotifications());
    this.store.dispatch(HomeActions.getHomeData());

    this.isStoriesLoading$ = this.store.select(fromHome.selectImpactStoriesLoading);
    this.store.pipe(select(fromRoot.selectBiometricLogin), takeUntil(this.onDestroy$)).subscribe((value) => {
      this.biometricLogin = value;
    });

    this.store.pipe(select(fromHome.selectAccountsHomepage), takeUntil(this.onDestroy$)).subscribe((value) => {
      this.accountsHomepage = value;
    });

    this.store.pipe(select(fromHome.selectCrowdInvestorData), takeUntil(this.onDestroy$)).subscribe((value) => {
      this.crowdinvestingValues = value;
    });

    this.store.pipe(select(fromHome.selectUnseenNotifications), takeUntil(this.onDestroy$)).subscribe((notifications) => {
      this.notifications = notifications.filter((item) => item.attributes.data.type !== 'impact_campaign');

      this.notification = null;
      setTimeout(() => {
        this.notification = notifications[notifications.length - 1];
      }, 100);
    });

    this.store.pipe(select(fromHome.selectTechnicalIssuesReporting), takeUntil(this.onDestroy$)).subscribe((value) => {
      this.technicalIssues = value;
    });

    this.store.pipe(select(fromHome.selectImpactMetrics), takeUntil(this.onDestroy$)).subscribe((metrics) => {
      if (!metrics.length) {
        return;
      }
      this.impactMetrics = metrics;
    });

    this.store.pipe(select(fromHome.selectImpactStoriesFiltered), takeUntil(this.onDestroy$)).subscribe((stories) => {
      if (!stories.length) {
        return;
      }

      this.impactStories = stories;
      this.stories = stories;
      this.fillPagedStories(stories);
    });

    this.store.pipe(select(fromHome.selectShowImpactStoriesFilter), takeUntil(this.onDestroy$)).subscribe((value) => {
      if (!value) {
        return;
      }
      this.showFilter = value;
    });

    this.store.pipe(select(fromHome.selectImpactStoriesFilter), takeUntil(this.onDestroy$)).subscribe((value) => {
      this.filterValue = value;
    });

    this.store.pipe(select(fromAccount.selectCustomer), takeUntil(this.onDestroy$)).subscribe((customer) => {
      this.customer = customer;
      this.currencyShares = customer.app_location === 'ch' ? 'CHF' : 'EUR';

      this.activeLanguage = this.customer.language;
      this.has3aAccount = !!customer.unified_accounts.find((account) => account.kind === '3a');
      this.active3aAccount = customer.unified_accounts.find((account) => account.kind === '3a' && isCustomer(account.step_status));

      this.oddCheck(this.customer.saxo_data_renewal_expires_at);
    });
    this.promoCampaigns$ = this.store.select(fromHome.selectPromoCampaigns);

    this.store.pipe(select(fromGrow.selectInyovaGrowAccount), takeUntil(this.onDestroy$)).subscribe((value) => {
      this.inyovaGrowAccount = value;
    });

    this.route.queryParams.subscribe((params) => {
      if (params.showSurvey) {
        void this.openSuperhumanSurvey();
        // reset query param
        void this.router.navigateByUrl('/tabs/home');
      }

      if (params.openExternal) {
        this.authService
          .getLastAuthToken()
          .pipe(take(1))
          .subscribe(() => {
            if (params.openExternal.includes('new-account')) {
              void this.openBannerModal('3a', 'new-account/3a');
            } else {
              void this.openExternalInyovaLink(params.openExternal as string);
            }
            void this.router.navigateByUrl('/tabs/home');
          });
      }
    });

    this.store.pipe(select(fromHome.selectImpactInitialized), takeUntil(this.onDestroy$)).subscribe((isInitialized) => {
      this.isInitialized = isInitialized;
    });
  }

  async openBannerModal(modalType: string, externalPage: string): Promise<void> {
    this.trackingService.trackActivity(`[Banner]: Open Modal ${modalType}`);

    this.statusBarStyling.setBackgroundColor('dialog');
    const modal = await this.modalController.create({
      component: HomeBannerModalComponent,
      cssClass: 'app-fullscreen',
      componentProps: {
        appLocation: this.customer.app_location,
        language: this.activeLanguage,
        externalPage,
        modalType
      },
      presentingElement: this.routerOutlet.nativeEl
    });
    void modal.onDidDismiss().then(() => {
      this.statusBarStyling.setBackgroundColor('base');
    });
    return modal.present();
  }

  async open3aModal(): Promise<void> {
    this.statusBarStyling.setBackgroundColor('dialog');
    const modal = await this.modalController.create({
      component: HomePillar3aComponent,
      cssClass: 'app-fullscreen',
      presentingElement: this.routerOutlet.nativeEl
    });
    modal.onDidDismiss().then((res: { data: { next: undefined | 'account' | 'transfer' | 'savings' } }) => {
      this.store.dispatch(RootActions.setSelectedAccount({ account: this.active3aAccount }));
      this.store.dispatch(
        AccountActions.setSwitchAccountLoading({
          show: true,
          message: this.translateService.instant(`SHARED.accountSwitchSpinner.${this.active3aAccount.kind}`, {
            kidsName: this.active3aAccount.owner_name
          })
        })
      );
      this.store.dispatch(AccountActions.getCurrentAccount({ id: this.active3aAccount.id }));

      switch (res.data.next) {
        case 'account':
          this.router.navigate(['/tabs/account/invest-details']);
          break;
        case 'transfer':
          this.router.navigate(['/tabs/account/transfer-3a']);
          break;
        case 'savings':
          this.router.navigate(['/tabs/account/invest-money']);
          break;
      }
      this.statusBarStyling.setBackgroundColor('base');
    });
    return modal.present();
  }

  async openSuperhumanSurvey() {
    this.statusBarStyling.setBackgroundColor('dialog');
    const modal = await this.modalController.create({
      component: HomeSuperhumanSurveyComponent,
      cssClass: 'app-fullscreen',
      presentingElement: await this.modalController.getTop()
    });
    void modal.onDidDismiss().then(() => {
      this.statusBarStyling.setBackgroundColor('base');
    });
    return modal.present();
  }

  getObjectKeys(object: any): Array<any> {
    return Object.keys(object);
  }

  hasTechnicalIssues(account: CustomerAccountItem): boolean {
    return this.technicalIssues[`${this.customer.app_location}${account.kind}`] as boolean;
  }

  loadStories(event: any) {
    setTimeout(() => {
      event.target.complete();
      this.storiesLimit += 5;
      this.fillPagedStories(this.stories);
    }, 500);
  }

  openDetail(story: ImpactStoriesItem): void {
    this.amplitudeTrack('Article Opened', { storyId: story.id });
    if (story.attributes.engagement_campaign) {
      if (IMPACT_CAMPAIGNS_INYOVA_IDS.includes(Number(story.attributes.inyova_id))) {
        this.openCampaignNotification(story.id, 'News');
      } else {
        this.openEngagementDetail(story);
      }
    } else if (IMPACT_CAMPAIGNS_UPDATE_INYOVA_IDS.includes(Number(story.attributes.inyova_id))) {
      this.openCampaignUpdate(story.id);
    } else {
      this.openImpactDetail(story);
    }
  }

  dissmissNotification(notification: Notification, autoDismiss = false) {
    if (!autoDismiss) {
      this.track(`[Notification dismiss] ${notification.attributes.title}`);
    }
    this.notificationResources.markAsSeen(notification.id).subscribe(() => {
      this.store.dispatch(HomeActions.getCustomerNotifications());
    });
  }

  goToPerformance(accountId: string): void {
    let target = '/tabs/performance';
    if (!accountId) {
      this.store.dispatch(RootActions.setCrowdInvestorAccountStatus({ active: true }));
      this.amplitudeTrack('Performance Opened', {
        action_source: 'on_page_link',
        kind: 'ci'
      });
    } else {
      const account = this.customer.unified_accounts.filter(({ id }) => id === accountId)[0];
      if (account.kind === 'interest') {
        target = '/tabs/grow-performance';
      }
      this.amplitudeTrack('Performance Opened', {
        action_source: 'on_page_link',
        kind: account.kind
      });
      this.store.dispatch(RootActions.setCrowdInvestorAccountStatus({ active: false }));
      this.store.dispatch(RootActions.setSelectedAccount({ account }));
    }

    void this.router.navigate([target]);
  }

  ionViewDidEnter(): void {
    this.subscription = this.platform.backButton.subscribeWithPriority(9999, () => {
      // If there is an active modal, do close on hardware back button
      void this.modalController.getTop().then((val) => {
        if (val) {
          void this.modalController.dismiss();
        } else if (navigator && navigator['app']) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          navigator['app'].exitApp();
        }
      });
    });
  }

  ionViewWillLeave() {
    this.subscription.unsubscribe();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  ngOnInit() {
    void this.showGreennessSurvey(this.customer);

    this.store.dispatch(HomeActions.getPromoCampaigns());

    this.languageService.selected.pipe(takeUntil(this.onDestroy$)).subscribe((value) => {
      this.activeLanguage = value;
      this.store.dispatch(HomeActions.getImpactStories());
      this.store.dispatch(HomeActions.getImpactMetrics());
      this.store.dispatch(HomeActions.getCustomerNotifications());
    });
    this.storage
      .get(IonicStorageKeys.BIOMETRIC_LOGIN_PREF)
      .then((value) => {
        if (!(value === true || value === false) && this.biometricLogin && this.biometricLogin.available) {
          void this.authService.checkAuthPreferencies();
        }
      })
      .catch(() =>
        // eslint-disable-next-line no-console
        console.log('Something went wrong')
      );
  }

  openNotificationPage(notification: Notification) {
    this.track(`[Notification] ${notification.attributes?.data?.engagement ? 'Engagement: ' : ''}${notification.attributes?.title}`);
    this.dissmissNotification(notification, true);

    if (notification.attributes?.data?.page) {
      if (notification.attributes.data.page === 'risk-recheck') {
        void this.openRiskRecheck(notification);
        return;
      }

      if (notification.attributes.data.page === 'superhuman') {
        void this.openSuperhumanSurvey();
        return;
      }

      if (notification.attributes?.data?.account_id) {
        if (notification.attributes.data.page === EXTERNAL_URLS.pillar3a) {
          void this.openBannerModal('3a', 'new-account/3a');
        } else {
          this.store.dispatch(
            AccountActions.getCurrentAccount({
              id: notification.attributes.data.account_id,
              redirectURL: this.notificationService.getRedirectPage(notification.attributes.data.page)
            })
          );
        }
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        void this.router.navigateByUrl(this.notificationService.getRedirectPage(notification.attributes.data.page));
      }
    }
  }

  track(event: string): void {
    this.trackingService.trackActivity(event);
  }

  openImpactDetail(story: ImpactStoriesItem): void {
    this.store.dispatch(HomeActions.getImpactStoryDetailSuccess({ data: story as ImpactStoryDetail }));
    void this.router.navigate(['/tabs/home/impact-news', story.id]);
  }

  openEngagementDetail(story: ImpactStoriesItem): void {
    this.track(`[News] Engagement: ${story.attributes.title}`);
    this.store.dispatch(HomeActions.getImpactStoryDetailSuccess({ data: story as ImpactStoryDetail }));
    void this.router.navigate(['/tabs/home/engagement', story.id]);
  }

  openGrow(): void {
    const target = this.inyovaGrowAccount && this.inyovaGrowAccount.status === InyovaGrowAccountStatus.INITIAL ? '/grow/risk' : '/grow/intro';
    void this.router.navigate([target]);
  }

  private openExternalInyovaLink(target: string): Promise<void> {
    let url = '';

    if (target === 'default3a') {
      const account3a = this.customer.accounts.find((item) => item.kind === '3a');
      url = `${environment.customersApp[this.customer.app_location]}/?customer_token=${localStorage.getItem(
        LocalStorageKeys.AUTHENTICATION_TOKEN
      )}&selected_account=${account3a ? account3a.id : null}`;
    } else {
      url = `${environment.customersApp[this.customer.app_location]}/?customer_token=${localStorage.getItem(
        LocalStorageKeys.AUTHENTICATION_TOKEN
      )}&redirect=${target}`;
    }

    const browser = Browser.open({ url });

    void Browser.addListener('browserFinished', () => {
      this.router.navigateByUrl('/public/login').finally(noop);
    });

    return browser;
  }

  private fillPagedStories(stories: ImpactStoriesItem[]) {
    if (stories && stories.length < this.storiesLimit) {
      this.pagedStories = stories;
      return;
    }

    this.pagedStories = [...stories.slice(0, this.storiesLimit)];
  }

  async openPicker() {
    let index = 0;
    if (this.filterValue) {
      index = this.filterValue === 'shareholderEngagement' ? 1 : 2;
    }

    const picker = await this.pickerController.create({
      columns: [
        {
          name: 'main',
          options: [
            { text: this.translateService.instant('SHARED.actions.showAll'), value: null },
            { text: this.translateService.instant('INSIGHTS.impact.engagement.category'), value: 'shareholderEngagement' },
            { text: this.translateService.instant('INSIGHTS.impact.stories.title1'), value: 'impactStories' }
          ],
          selectedIndex: index
        }
      ],
      buttons: [
        {
          text: this.translateService.instant('SHARED.actions.cancel'),
          role: 'cancel'
        },
        {
          text: this.translateService.instant('SHARED.actions.confirm'),
          handler: (value: { main: { value: string } }) => {
            if (!value.main.value) {
              this.track('[Filter impact news] Deactivated');
            } else {
              this.track(`[Filter impact news] ${value.main.value}`);
            }
            this.store.dispatch(HomeActions.changeImpactStoriesFilter({ value: value.main.value }));
          }
        }
      ]
    });

    // FIX: Open issue on github: https://github.com/ionic-team/ionic/issues/17664 - partially fixed
    picker.columns[0].options.forEach((element) => {
      delete element.selected;
      delete element.duration;
      delete element.transform;
    });

    await picker.present();
  }

  openCampaignNotification(id: number, source = 'Notification'): void {
    this.trackingService.trackActivity(`[${source}] Open campaign detail`);
    void this.router.navigate([`/tabs/home/campaign/${id}`]);
  }

  openCampaignUpdate(id: number): void {
    this.trackingService.trackActivity(`Open campaign update detail `);
    void this.router.navigate([`/tabs/home/campaign-update/${id}`]);
  }

  openRiskRecheck(banner: Notification) {
    this.trackingService.trackActivity('[in-app notification] Open risk-recheck webapp link');
    const url = `${environment.customersApp[this.customer.app_location]}/?customer_token=${localStorage.getItem(
      LocalStorageKeys.AUTHENTICATION_TOKEN
    )}&redirect=strategy,risk-check&selected_account=${banner.attributes.data.account_id}`;

    Browser.open({ url }).finally(noop);
    void Browser.addListener('browserFinished', () => {
      this.router.navigate(['/public/login']).finally(noop);
    });
  }

  async runGreennessSurvey(showSurvey: GreennessSurveyTypes, appLocation: string, customerId: number) {
    this.statusBarStyling.setBackgroundColor('dialog');
    const modal = await this.modalController.create({
      component: AccountGreennessModalComponent,
      cssClass: 'greenness-modal',
      componentProps: {
        showSurvey,
        appLocation
      },
      presentingElement: this.routerOutlet.nativeEl,
      backdropDismiss: false,
      handle: false,
      handleBehavior: 'none',
      showBackdrop: true
    });
    void modal.onDidDismiss().then(({ data }) => {
      this.statusBarStyling.setBackgroundColor('base');

      if (typeof data === 'undefined') {
        const currentDate: Date = new Date();
        const tillDate: string = new Date(currentDate.getTime() + 7 * 24 * 60 * 60 * 1000).toISOString();
        localStorage.setItem(`${LocalStorageKeys.HIDE_GREENNESS_SURVEY}_${customerId}`, tillDate);
        return;
      }

      this.store.dispatch(AccountActions.submitGreennessSurvey({ data }));
    });
    return modal.present();
  }

  oddCheck(saxoCheckExpiresAt: null | string) {
    if (saxoCheckExpiresAt === null || typeof saxoCheckExpiresAt === 'undefined') {
      return;
    }

    this.storage.get(IonicStorageKeys.HIDE_ODD_MODAL).then((value: null | boolean): void => {
      this.displayOddModal = value === null || !value;
    });
  }

  async showGreennessSurvey(customer: Customer) {
    const showSurvey = customer.show_greenness_survey;
    const tillDate: null | string = await localStorage.getItem(`${LocalStorageKeys.HIDE_GREENNESS_SURVEY}_${customer.id}`);

    if (typeof showSurvey === 'undefined' || showSurvey === null) {
      if (tillDate !== null) {
        localStorage.removeItem(`${LocalStorageKeys.HIDE_GREENNESS_SURVEY}_${customer.id}`);
      }
      return;
    }

    if (typeof tillDate !== 'undefined' && tillDate !== null && new Date() < new Date(tillDate)) {
      return;
    }

    void this.runGreennessSurvey(showSurvey, customer.app_location, customer.id);
  }

  onReview() {
    this.trackingService.trackActivity('[Button] Odd review');
    this.amplitudeTrack('ODD Started', {
      account_bank: 'saxo',
      deadline: this.customer.saxo_data_renewal_expires_at,
      days_to_deadline: getRemainingTime(this.customer.saxo_data_renewal_expires_at).days
    });
    const account3b: CustomerAccountItem | undefined = this.customer.accounts.find((accItem) => accItem.kind === '3b');
    const url = `${environment.customersApp.ch}?customer_token=${localStorage.getItem(LocalStorageKeys.AUTHENTICATION_TOKEN)}${
      typeof account3b !== 'undefined' ? `&selected_account=${account3b.id}` : ''
    }&redirect=odd`;
    window.open(url, '_system');
    void this.storage.get(IonicStorageKeys.LOGIN_TYPE).then((value) => {
      this.store.dispatch(AccountActions.customerLogoutAndUnpairDevice({ isGoogleCustomer: value === LoginType.GOOGLE }));
    });
  }

  onDoItLater() {
    this.trackingService.trackActivity('[Button] Odd do it later');
    this.storage.set(IonicStorageKeys.HIDE_ODD_MODAL, true).then(() => {
      this.displayOddModal = false;
    });
  }

  amplitudeTrack(eventName: string, data?: any) {
    this.amplitudeService.trackEvent(eventName, data);
  }
}
