import { Customer, CustomerAccount, CustomerAccountItem } from '@inyova/models';

import { createAction, props } from '@ngrx/store';

import { Languages } from '@app/app.constants';
import {
  ABTest,
  AccountDocument,
  AccountLibertyDocument,
  AccountLibertyMeta,
  CustomerPersonalDetails,
  FinancialSituation,
  GrowDocument,
  PersonalDetails,
  ProfileBannerItem,
  Promotions,
  StaticDocument,
  Transaction
} from '@shared/models/Account';

export const addNewDevice = createAction('[Account] Add new device', props<{ deviceUuid: string; token?: string }>());

export const customerLogout = createAction('[Account] Customer Log out');

export const customerLogoutAndUnpairDevice = createAction('[Account] Customer Log out and unpair device', props<{ isGoogleCustomer: boolean }>());

export const changeLanguage = createAction('[Account] Change language', props<{ language: Languages }>());

export const changePassword = createAction(
  '[Account] Change password',
  props<{ passwordCurrent: string; password: string; passwordConfirmation: string }>()
);

export const checkPassword = createAction(
  '[Account] Check current password',
  props<{ password: string; twoFA: boolean; enablePathActivated: boolean; nextScreen: 'email' | 'phone' | '2fa' }>()
);

export const disableBiometricLogin = createAction('[Account] Disable biometric login', props<{ token: string }>());

export const disablePushNotifications = createAction('[Account] Disable push notifications');

export const enableBiometricLogin = createAction('[Account] Enable biometric login');

export const getABTests = createAction('[Account] Get AB tests');

export const getABTestsSuccess = createAction('[Account] Get AB tests success', props<{ data: ABTest[] }>());

export const getCustomer = createAction('[Account] Get customer', props<{ doLogin: boolean }>());

export const getCurrentAccount = createAction('[Account] Get current account', props<{ id: string; redirectURL?: string }>());

export const getCustomerDocuments = createAction('[Account] Get customer documents');
export const getCustomerDocumentsSuccess = createAction('[Account] Get customer documents success', props<{ documents: AccountDocument[] }>());
export const getCustomerDocumentsFail = createAction('[Account] Get customer documents fail');

export const getDocuments3A = createAction('[Account] Get documents 3A', props<{ size: number; pageIndex: number }>());
export const getDocuments3ASuccess = createAction(
  '[Account] Get documents 3A success',
  props<{ documents: AccountLibertyDocument[]; meta: AccountLibertyMeta }>()
);
export const getDocuments3AFail = createAction('[Account] Get documents 3A fail');

export const getStaticDocuments = createAction('[Account] Get static documents');
export const getStaticDocumentsSuccess = createAction('[Account] Get static documents success', props<{ documents: StaticDocument[] }>());

export const getDocumentsGrow = createAction('[Account] Get new documents grow');
export const getDocumentsGrowSuccess = createAction('[Account] Get documents grow success', props<{ documents: GrowDocument[] }>());
export const getDocumentsGrowFail = createAction('[Account] Get documents grow fail');

export const getStaticDocumentsFail = createAction('[Account] Get static documents fail');

export const getTransactions = createAction('[Account] Get transactions');
export const getTransactionsSuccess = createAction('[Account] Get transactions success', props<{ data: { [key: string]: Transaction[] } }>());
export const getTransactionsFail = createAction('[Account] Get transactions fail');

export const getPromotions = createAction('[Account] Get promotions');

export const getPromotionsSuccess = createAction('[Account] Get promotions success', props<{ data: Promotions }>());

export const loginKidAccount = createAction('[Account] Login kid account', props<{ id: string }>());

export const validateDevice = createAction('[Account] Validate device', props<{ deviceUuid: string; biometricToken: string }>());

export const redeemPromocode = createAction('[Account] Redeem promocode', props<{ code: string }>());

export const send2faAuthorizationCode = createAction('[Account] Send 2fa authorization code');

export const sendSupportRequest = createAction('[Account] Send support request', props<{ message: string; account_type?: string }>());

export const getAccountDetails = createAction('[Account] Get account details');

export const setAccountDetails = createAction('[Account] Set account details', props<{ data: PersonalDetails }>());

export const setCustomer = createAction('[Account] Set customer', props<{ data: Customer }>());

export const setCurrentAccountID = createAction('[Account] Set current account ID', props<{ id: string }>());

export const setCurrentAccount = createAction('[Account] Set current account', props<{ data: CustomerAccount }>());

export const setDocuments3APagination = createAction('[Account] Set documents 3A pagination', props<{ total: number }>());

export const toggle2faAuthorization = createAction('[Account] Toggle 2fa authorization', props<{ code: string }>());

export const updateAddress = createAction('[Account] Update address', props<{ personalDetails: Partial<CustomerPersonalDetails> }>());

export const updateCustomer = createAction('[Account] Update customer data', props<{ data: Partial<Customer> }>());

export const updateCurrentAccount = createAction('[Account] Update current account', props<{ data: CustomerAccountItem }>());

export const updatePhone = createAction('[Account] Update customer phone', props<{ phoneNumber: string; needsVerification: boolean }>());

export const updatePhoneNumberAndTrigger2faMessage = createAction('[Account] Update phone number and trigger 2fa', props<{ num: string }>());

export const sendVerifyPhoneCode = createAction('[Account] Send verify phone code');
export const verifyPhoneNumber = createAction('[Account] Verify phone number', props<{ code: string }>());

export const updateEmail = createAction('[Account] Update customer email', props<{ data: { email: string } }>());

export const setSwitchAccountLoading = createAction('[Account] toggle account loading state', props<{ show: boolean; message: string }>());

export const updateSp = createAction('[Account] Update savings plan', props<{ data: number; id: string; isWisher: boolean; is3a?: boolean }>());
export const updateSpSuccess = createAction(
  '[Account] Update savings plan success',
  props<{ id: string; isWisher: boolean; is3a?: boolean; data?: number }>()
);
export const updateSpFailed = createAction('[Account] Update savings plan failed');

export const getProfileBanners = createAction('[Account] Get profile banners');
export const getProfileBannersSuccess = createAction('[Account] Get profile banners success', props<{ data: ProfileBannerItem[] }>());
export const getProfileBannersFail = createAction('[Account] Get profile banners fail');

export const getFinancialSituation = createAction('[Account] Get financial situation');
export const getFinancialSituationSuccess = createAction('[Account] Get financial situation success', props<{ data: FinancialSituation }>());
export const getFinancialSituationFail = createAction('[Account] Get financial situation fail');

export const setInyovaGrowAccountFlags = createAction(
  '[Account] change inyova grow flags',
  props<{ has_interest_account: boolean; show_interest_account_banner: boolean; growAccountId: string }>()
);
export const submitGreennessSurvey = createAction(
  '[Account] Submit greenness survey',
  props<{ data: { greenness_score?: number; net_worth?: string; annual_income?: string } }>()
);
export const submitGreennessSurveySuccess = createAction('[Account] Submit greenness survey success', props<{ success: boolean }>());
export const submitGreennessSurveyFail = createAction('[Account] Submit greenness survey fail', props<{ success: boolean }>());
