import { Component, Input, OnInit } from '@angular/core';
import { getGrowProjectBadgeDetails } from '@inyova/grow';
import { AmplitudeService } from '@inyova/inyova-shared';
import { ModalController } from '@ionic/angular';

import { GrowFund, InyovaGrowAccount, InyovaGrowAccountStatus, GrowProjectStatus } from '@app/shared/models/Grow';
import { TrackingService } from '@shared/services/tracking.service';

@Component({
  selector: 'app-inyova-grow-fund-modal',
  templateUrl: './inyova-grow-fund-modal.component.html',
  styleUrls: ['./inyova-grow-fund-modal.component.scss']
})
export class InyovaGrowFundModalComponent implements OnInit {
  @Input() fund: GrowFund;
  @Input() isPerformancePage = false;
  @Input() growAccount: InyovaGrowAccount;
  @Input() showDepositWidget = false;

  accountStatus = InyovaGrowAccountStatus;
  projectStatus = GrowProjectStatus;

  showInfo = {
    status: false
  };

  constructor(
    private modalController: ModalController,
    private trackingService: TrackingService,
    private amplitudeService: AmplitudeService
  ) {}

  ngOnInit() {
    this.track(`[Grow project modal] Opened: Fund`);
  }

  onDismiss() {
    this.track(`[Grow project modal] Button: Dismiss Fund`);
    this.amplitudeService.trackEvent('Grow Investment Flow Closed', { investment_flow_step: 'grow_project_info' });
    this.modalController.dismiss();
  }

  track(event: string) {
    this.trackingService.trackActivity(event);
  }

  toggleInfo(item: string) {
    this.showInfo[item] = !this.showInfo[item];
  }

  getInvestmentBadge(status: GrowProjectStatus): { type: string; key: string } {
    return getGrowProjectBadgeDetails(status);
  }
}
