// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep .inyova-grow-project-content h2,
:host ::ng-deep .inyova-grow-project-content h3 {
  font-size: var(--fs-4);
  line-height: var(--flh-4);
  font-weight: var(--fw-700);
  letter-spacing: var(--fls-3);
  margin: 0;
  margin-bottom: var(--spacing-4);
}
:host ::ng-deep .inyova-grow-project-content p {
  font-size: var(--fs-4);
  line-height: var(--flh-5);
  font-weight: var(--fw-400);
  letter-spacing: var(--fls-3);
  margin-bottom: var(--spacing-8);
}

ion-content {
  --padding-start: var(--spacing-6);
  --padding-end: var(--spacing-6);
  --padding-bottom: var(--spacing-8);
}

h3 {
  margin-top: var(--spacing-2);
  margin-bottom: var(--spacing-8);
}

.inyova-grow-project-content {
  margin-bottom: var(--spacing-8);
}

.inyova-grow-figures {
  margin-bottom: var(--spacing-8);
}
.inyova-grow-figures h2 {
  margin-bottom: var(--spacing-3);
}

.inyova-grow-project-action {
  background: var(--color-core-brand-3-1);
  background: linear-gradient(0deg, var(--color-core-brand-3-1) 80%, rgba(249, 246, 240, 0) 100%);
  padding: var(--spacing-7) var(--spacing-6) var(--spacing-8) var(--spacing-6);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
