import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AmplitudeService, ThousandSeparatorPipe } from '@inyova/inyova-shared';
import { GREENNESS_INCOME_OPTIONS, GREENNESS_NET_WORTH_OPTIONS, GreennessSurveyTypes } from '@inyova/models';

@Component({
  selector: 'app-account-greenness-survey',
  templateUrl: './account-greenness-survey.component.html',
  styleUrls: ['./account-greenness-survey.component.scss']
})
export class AccountGreennessSurveyComponent implements OnInit {
  @Input() webApp = false;
  @Input() showSurvey: GreennessSurveyTypes;
  @Input() appLocation: string;
  @Input() translationKey: 'survey' | 'onboardingSurvey' = 'survey';
  @Output() surveySubmit: EventEmitter<{ score?: number; income?: string }> = new EventEmitter<{
    score?: number;
    income?: string;
    worth?: string;
  }>();

  incomeOptions: { caption: string; value: string }[];
  netWorthOptions: { caption: string; value: string }[];

  form: FormGroup = this.fb.group({});

  infoList = {
    income: false,
    worth: false
  };

  constructor(
    private fb: FormBuilder,
    private thousandPipe: ThousandSeparatorPipe,
    private amplitudeService: AmplitudeService
  ) {}

  ngOnInit() {
    this.incomeOptions = this.formatCaption(GREENNESS_INCOME_OPTIONS, this.appLocation);
    this.netWorthOptions = this.formatCaption(GREENNESS_NET_WORTH_OPTIONS, this.appLocation);
    this.generateFormFields(this.showSurvey);
  }

  generateFormFields(showSurvey: null | 'greenness' | 'greenness_and_finance' | 'finance'): void {
    switch (showSurvey) {
      case 'greenness':
        this.form.addControl('score', new FormControl('', Validators.required));
        return;
      case 'finance':
        this.form.addControl('income', new FormControl('', Validators.required));
        this.form.addControl('worth', new FormControl('', Validators.required));
        return;
      default:
        this.form.addControl('score', new FormControl('', Validators.required));
        this.form.addControl('income', new FormControl('', Validators.required));
        this.form.addControl('worth', new FormControl('', Validators.required));
    }
  }

  onToggleInfo(listItem: 'income' | 'worth') {
    this.infoList[listItem] = !this.infoList[listItem];
  }

  formatCaption(options: { caption: string; value: string }[], appLocation: string): { caption: string; value: string }[] {
    return options.map((option) => {
      const capArr = option.caption.split(' ');
      const capArrLength = capArr.length;
      if (capArrLength === 3) {
        capArr[0] = this.thousandPipe.transform(capArr[0]);
      }
      capArr[capArrLength - 1] = `${this.thousandPipe.transform(capArr[capArrLength - 1])} ${appLocation === 'ch' ? 'CHF' : 'EUR'}`;
      return { ...option, caption: capArrLength === 3 ? `${capArr[0]} - ${capArr[capArrLength - 1]}` : `${capArr[0]} ${capArr[capArrLength - 1]}` };
    });
  }

  onSubmit() {
    this.amplitudeService.updateUserProperties([
      { propName: 'customer_greenness_score', propValue: this.form.get('score')?.value || 'none' },
      { propName: 'customer_income', propValue: this.form.get('income')?.value || 'none' },
      { propName: 'customer_wealth', propValue: this.form.get('worth')?.value || 'none' }
    ]);
    this.surveySubmit.emit(this.form.value);
  }
}
