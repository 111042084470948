/* eslint-disable multiline-ternary */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AmplitudeService } from '@inyova/inyova-shared';
import { AccountFeatureFlags, Customer, CustomerAccount, CustomerUnifiedAccountItem, PortfolioAllocationV2 } from '@inyova/models';
import { isCustomer } from '@inyova/utils';
import { IonRouterOutlet, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as AccountActions from '@account/account.actions';
import * as HomeActions from '@home/home.actions';
import * as StrategyActions from '@strategy/strategy.actions';
import * as fromAccount from '@account/account.reducers';
import * as fromRoot from '@app/app.reducers';
import * as fromStrategy from '@strategy/strategy.reducers';
import * as fromHome from '@home/reducers';

import { ABTests } from '@app/app.constants';
import { formatDate } from '@app/home/home.utils';
import { HomeBalanceSheetModalComponent } from '@app/home/modals';
import { PortfolioAllocation } from '@app/shared/models/Strategy';
import { StatusBarStylingService } from '@app/shared/services/status-bar-styling.service';
import { CompanyDetailComponent } from '@shared/modals';
import { StockCompanyDetailComponent } from '@shared/modals/company-detail/stock-company-detail.component';
import {
  CompaniesStockPerformance,
  CrowdInvestingValues,
  PerformanceChart,
  PerformanceFilterRange,
  PerformanceGraphItem,
  PerformanceSummary,
  PerformanceType,
  ReportEndValues,
  Stock,
  TechnicalIssuesReporting
} from '@shared/models/Home';
import { State } from '@shared/models/State';
import { TrackingService } from '@shared/services/tracking.service';

enum TabList {
  performance,
  strategy,
  activity,
  risk
}

@Component({
  selector: 'app-home-performance',
  styleUrls: ['home-performance.component.scss'],
  templateUrl: './home-performance.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomePerformanceComponent implements OnDestroy {
  protected readonly onDestroy$ = new Subject<void>();
  readonly performanceSummary$: Observable<PerformanceSummary> = this.store.select(fromHome.selectPerformanceSummary);
  readonly technicalIssues$: Observable<TechnicalIssuesReporting> = this.store.select(fromHome.selectTechnicalIssuesReporting);
  readonly companiesStockPerformance$: Observable<CompaniesStockPerformance[]> = this.store.select(fromHome.selectCompaniesStockPerformance);

  readonly accountStocks$: Observable<Stock[]> = this.store.select(fromHome.selectAccountStocks);

  readonly crowdInvestingValues$: Observable<CrowdInvestingValues> = this.store.select(fromHome.selectCrowdInvestorData);
  readonly performance$: Observable<PerformanceChart> = this.store.select(fromHome.selectPerformance);
  readonly performanceData$: Observable<PerformanceGraphItem[]> = this.store.select(fromHome.selectPerformanceData);
  readonly isCrowdInvestorAccountActive$: Observable<boolean> = this.store.select(fromRoot.selectCrowdInvestorAccountActive);
  readonly fakeDoorType$: Observable<string | boolean> = this.store.select(fromAccount.selectActiveABTest(ABTests.FAKE_DOOR));
  readonly endValues$: Observable<ReportEndValues> = this.store.select(fromHome.selectEndValuesData);
  readonly performanceAllValue$: Observable<number> = this.store.select(fromHome.selectPerformanceAll);
  readonly isAccountLoading$: Observable<{ show: boolean; message: string }> = this.store.select(fromAccount.selectIsAccountLoading);

  currentAccount!: CustomerAccount;
  selectedAccount: CustomerUnifiedAccountItem;
  customer!: Customer;
  minimumInvestment = 0;

  eventsMap = {
    [PerformanceFilterRange.WEEK]: '1_week',
    [PerformanceFilterRange.MONTH]: '1_month',
    [PerformanceFilterRange.HALF_YEAR]: '6_months',
    [PerformanceFilterRange.YEAR]: '1_year',
    [PerformanceFilterRange.ALL]: 'max',
    [PerformanceFilterRange.CURRENT_YEAR]: 'current_year',
    [PerformanceType.BALANCE]: 'chf_selected',
    [PerformanceType.PERFORMANCE]: 'percentage_selected'
  };

  contentTabList = TabList;
  switcherTabs = [
    { id: this.contentTabList.performance, value: 'PERFORMANCE.tabName' },
    { id: this.contentTabList.strategy, value: 'STRATEGY.tabName' }
    // TODO: Active them when we have content
    // { id: this.contentTabList.activity, value: 'translation.key' },
    // { id: this.contentTabList.risk, value: 'translation.key' }
  ];

  activeTab: number | string = this.switcherTabs[0].id;
  showStockPerfInfo: boolean;
  isReportingAvailable: boolean;
  companiesStockPerformanceInitialised = false;

  homeStrategyDetails$: Observable<{ riskLevel: string; nextStrategyChangeAt: string }>;
  portfolioAllocation$: Observable<PortfolioAllocation>;
  portfolioAllocationV2$: Observable<PortfolioAllocationV2>;

  infoList = {
    updateDate: false
  };

  constructor(
    private amplitudeService: AmplitudeService,
    private modalController: ModalController,
    private translateService: TranslateService,
    private routerOutlet: IonRouterOutlet,
    private store: Store<State>,
    private trackingService: TrackingService,
    private router: Router,
    private statusBarStyling: StatusBarStylingService
  ) {
    this.store.pipe(select(fromAccount.selectCustomerAndCurrentAccount), takeUntil(this.onDestroy$)).subscribe((value) => {
      this.currentAccount = value.currentAccount;
      this.customer = value.customer;

      this.minimumInvestment =
        this.customer.app_location === 'de' && this.currentAccount.has_low_investment_balance ? 2000 : this.currentAccount.minimum_investment;

      if (value.currentAccount.id !== '') {
        this.fetchData();
      }

      // call only when we know customers
      if (!this.companiesStockPerformanceInitialised && this.customer.accounts?.length) {
        this.customer.accounts.forEach((account) => {
          if (isCustomer(account.step_status)) {
            if (account.enabled_features.includes(AccountFeatureFlags.EVE2)) {
              this.store.dispatch(HomeActions.getAccountStocks({ accountID: account.id }));
            } else {
              this.store.dispatch(HomeActions.getCompaniesStockPerformance({ account }));
            }
          }
        });
        this.companiesStockPerformanceInitialised = true;
      }
    });

    this.technicalIssues$.pipe(takeUntil(this.onDestroy$)).subscribe((issues) => {
      this.isReportingAvailable = !issues[`${this.customer.app_location}${this.currentAccount.kind}`];
    });

    // fetch only init
    this.store.dispatch(HomeActions.getAllReportingsStatus());

    this.homeStrategyDetails$ = this.store.select(fromStrategy.selectHomeStrategyDetails);
    this.portfolioAllocation$ = this.store.select(fromStrategy.selectPortfolioAllocation);
    this.portfolioAllocationV2$ = this.store.select(fromStrategy.selectPortfolioAllocationV2);
    this.store.pipe(select(fromRoot.selectSelectedAccount), takeUntil(this.onDestroy$)).subscribe((account) => {
      this.selectedAccount = account;
    });
  }

  ionViewDidEnter() {
    if (this.selectedAccount && this.currentAccount.id !== this.selectedAccount.id) {
      this.store.dispatch(
        AccountActions.setSwitchAccountLoading({
          show: true,
          message: this.translateService.instant(`SHARED.accountSwitchSpinner.${this.selectedAccount.kind}`, {
            kidsName: this.selectedAccount.owner_name
          })
        })
      );
      this.store.dispatch(AccountActions.getCurrentAccount({ id: this.selectedAccount.id }));
    }

    this.store.dispatch(HomeActions.checkAccountStocksLanguage());
  }

  formatDate(date: string, seperator: '.' | '-' = '.') {
    return formatDate(date, seperator, this.translateService.currentLang);
  }

  showInfo(item: string): void {
    this.infoList[item] = !this.infoList[item];
  }

  fetchData(): void {
    this.store.dispatch(StrategyActions.getInvestmentData());
    this.store.dispatch(StrategyActions.getInvestmentDetails());
    this.store.dispatch(StrategyActions.getPortfolioAllocation());
    this.store.dispatch(HomeActions.getPerformanceSummary());
    this.store.dispatch(HomeActions.getBalanceSheet());
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  calculateAmount(minInvestment: number, depositsValue: number, withdrawalAll: number): number {
    return minInvestment - (depositsValue - withdrawalAll);
  }

  track(event: string) {
    this.trackingService.trackActivity(event);
  }

  onFilterChange(range: PerformanceFilterRange) {
    this.track(`[Performance chart] Change filter: ${range || 'ALL'}`);
    this.amplitudeService.trackEvent(`Performance Graph Engaged`, {
      performance_chart_time_horizon: this.eventsMap[range],
      kind: this.currentAccount.kind
    });
    this.store.dispatch(HomeActions.getPerformanceGraph(range));
  }

  onTypeChange(chartType: PerformanceType) {
    this.track(`[Performance chart] Selected chart: ${chartType}`);
    this.amplitudeService.trackEvent(`Performance Graph Engaged`, {
      performance_chart_ctas: this.eventsMap[chartType],
      kind: this.currentAccount.kind
    });
    this.store.dispatch(HomeActions.togglePerformanceGraphType({ chartType }));
  }

  onInvestDetails() {
    void this.router.navigate(['/tabs/account/invest-details']);
  }

  switchTabs(id: number | string) {
    this.track(`[Performance] Tab Clicked: ${TabList[id]}`);

    let tab = '';
    if (TabList[id] === 'performance') {
      tab = 'performance_tab_opened';
    } else if (TabList[id] === 'strategy') {
      tab = 'strategy_tab_opened';
    }

    this.amplitudeService.trackEvent(`Performance Graph Engaged`, { performance_chart_ctas: tab, kind: this.currentAccount.kind });
    this.activeTab = id;
  }

  switchStockPerfInfo(): void {
    this.showStockPerfInfo = !this.showStockPerfInfo;
  }

  async showCompanyDetail($event: { companyID: number; title: string }) {
    this.amplitudeService.trackEvent('Company Profile Opened', { kind: this.currentAccount.kind });
    this.trackingService.trackActivity(`[List] Top 3 Companies: ${$event.title}`);
    this.statusBarStyling.setBackgroundColor('dialog');
    this.store.dispatch(StrategyActions.getCompanyDetail({ companyId: $event.companyID }));
    const modal = await this.modalController.create({
      component: CompanyDetailComponent,
      cssClass: 'app-fullscreen',
      presentingElement: this.routerOutlet.nativeEl
    });
    void modal.onDidDismiss().then(() => {
      this.statusBarStyling.setBackgroundColor('base');
    });
    return modal.present();
  }

  async onViewCompanyDetail(stock: Stock) {
    this.amplitudeService.trackEvent('Company Profile Opened', { kind: this.currentAccount.kind });
    this.trackingService.trackActivity(`[List] Top 3 Companies: ${stock.company.name}`);
    this.statusBarStyling.setBackgroundColor('dialog');

    const modal = await this.modalController.create({
      component: StockCompanyDetailComponent,
      cssClass: 'app-fullscreen',
      presentingElement: this.routerOutlet.nativeEl,
      componentProps: {
        companyDetail: stock.company
      }
    });
    void modal.onDidDismiss().then(() => {
      this.statusBarStyling.setBackgroundColor('base');
    });
    return modal.present();
  }

  async openBalanceSheet(): Promise<void> {
    this.track(`[Button]: Opened Balance Sheet Modal`);
    this.amplitudeService.trackEvent('Portfolio Report Opened', { kind: this.currentAccount.kind });

    const modal = await this.modalController.create({
      component: HomeBalanceSheetModalComponent,
      componentProps: {
        currency: this.currentAccount.currency,
        language: this.translateService.currentLang,
        appLocation: this.customer.app_location
      },
      presentingElement: this.routerOutlet.nativeEl
    });
    return modal.present();
  }
}
