import { CampaignVideoComponent } from '@home/modals/home-impact-campaign-video/campaign-video.component';

import { AssetsSuccessModalComponent } from './assets-success-modal/assets-success-modal.component';
import { CompanyDetailComponent } from './company-detail/company-detail.component';
import { StockCompanyDetailComponent } from './company-detail/stock-company-detail.component';
import { GeneralConfirmModalComponent } from './general-confirm-modal/general-confirm-modal.component';

export const components = [
  CampaignVideoComponent,
  CompanyDetailComponent,
  StockCompanyDetailComponent,
  GeneralConfirmModalComponent,
  AssetsSuccessModalComponent
];

export { CampaignVideoComponent } from '@home/modals/home-impact-campaign-video/campaign-video.component';
export { AssetsSuccessModalComponent } from './assets-success-modal/assets-success-modal.component';
export { CompanyDetailComponent } from './company-detail/company-detail.component';
export { GeneralConfirmModalComponent } from './general-confirm-modal/general-confirm-modal.component';
export { StockCompanyDetailComponent } from './company-detail/stock-company-detail.component';
