import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import * as fromPipes from './pipes';
import { InyovaConfig } from './services';

@NgModule({
  imports: [CommonModule, TranslateModule],
  declarations: [...fromPipes.pipes],
  providers: [fromPipes.ThousandSeparatorPipe, fromPipes.DateTranslatePipe],
  exports: [...fromPipes.pipes]
})
export class InyovaSharedModule {}

@NgModule()
export class InyovaConfigModule {
  constructor(@Optional() @SkipSelf() parentModule?: InyovaConfigModule) {
    if (parentModule) {
      throw new Error('InyovaConfigModule is already loaded. Import it in the AppModule only');
    }
  }

  static forRoot(config: InyovaConfig): ModuleWithProviders<InyovaConfigModule> {
    return {
      ngModule: InyovaConfigModule,
      providers: [{ provide: InyovaConfig, useValue: config }]
    };
  }
}
