import { Component, Input, OnInit } from '@angular/core';
import { AmplitudeService } from '@inyova/inyova-shared';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

import { GrowProject, InyovaGrowAccount, GrowProjectStatus, GrowProjectStage, InyovaGrowAccountStatus } from '@app/shared/models/Grow';
import { TrackingService } from '@shared/services/tracking.service';

export enum TabList {
  DETAILS,
  RISKS,
  OPPORTUNITIES,
  IMPACT
}

@Component({
  selector: 'app-inyova-grow-project-modal',
  templateUrl: './inyova-grow-project-modal.component.html',
  styleUrls: ['./inyova-grow-project-modal.component.scss']
})
export class InyovaGrowProjectModalComponent implements OnInit {
  @Input() project: GrowProject;

  @Input() isPerformancePage = false;
  @Input() growAccount: InyovaGrowAccount;
  @Input() language: string;
  @Input() showDepositWidget = false;
  @Input() groupedInvestments: { status: GrowProjectStatus; amount: number }[];

  projectProgress = null;
  projectStatus = GrowProjectStatus;
  projectStage = GrowProjectStage;
  accountStatus = InyovaGrowAccountStatus;

  constructor(
    private modalController: ModalController,
    private trackingService: TrackingService,
    private translateService: TranslateService,
    private amplitudeService: AmplitudeService
  ) {
    this.language = this.translateService.currentLang;
  }

  ngOnInit() {
    this.track(`[Grow project modal] Opened: ${`Project ${this.project.id}`}`);
  }

  onTabChanged(tabId: number) {
    const tabListMap = {
      [TabList.DETAILS]: 'details_tab',
      [TabList.RISKS]: 'risk_tab',
      [TabList.OPPORTUNITIES]: 'opportunities_tab',
      [TabList.IMPACT]: 'impact_tab'
    };
    this.track(`[Grow project modal] Tab Clicked: ${TabList[tabId]}`);
    this.amplitudeService.trackEvent('Project Info Tab Opened', { info_tab_name: tabListMap[tabId] });
  }

  onDismiss() {
    this.track(`[Grow project modal] Button: Dismiss ${`Project ${this.project.id}`}`);
    this.amplitudeService.trackEvent('Grow Investment Flow Closed', { investment_flow_step: 'grow_project_info' });
    this.modalController.dismiss();
  }

  onToggleProject() {
    this.modalController.dismiss({
      projectId: this.project.id,
      markProject: this.groupedInvestments[0].status === GrowProjectStatus.INITIAL
    });
  }

  onFinishInvestment() {
    this.modalController.dismiss({
      projectId: this.project.id,
      markAndInvest: this.groupedInvestments[0].status === GrowProjectStatus.INITIAL
    });
  }

  track(event: string) {
    this.trackingService.trackActivity(event);
  }
}
