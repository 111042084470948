import { CustomerAccountItem } from '@inyova/models';

import { createAction, props } from '@ngrx/store';

import { AccountOptions, Notification, SavingsPlanSummary } from '@shared/models/Account';
import {
  BalanceSheet,
  CompaniesStockPerformance,
  CrowdInvestingValues,
  EngagementDetail,
  ImpactMetric,
  ImpactStoriesItem,
  ImpactStoryDetail,
  PerformanceFilterRange,
  PerformanceItem,
  PerformanceSummary,
  PerformanceType,
  ReportEndValues,
  SuperhumanPutAnswer,
  SuperhumanQuestion,
  ThirdPartyServiceItem,
  PromoCampaign,
  PromoCampaignItem,
  Stock
} from '@shared/models/Home';

export const changeImpactStoriesFilter = createAction('[Home] Change impact stories filter', props<{ value: null | string }>());

export const getEndValues = createAction('[Home] Get end values', props<{ account: CustomerAccountItem }>());

export const getHomeData = createAction('[Home] Get home data');

export const getEndValuesSuccess = createAction('[Home] Get end values success', props<{ account: CustomerAccountItem; data: ReportEndValues }>());

export const getGraphData = createAction('[Home] Get graph data', props<{ account: CustomerAccountItem }>());
export const getGraphDataSuccess = createAction(
  '[Home] Get graph data success',
  props<{ account: CustomerAccountItem; graphData: PerformanceItem[] }>()
);

// Performance graph
export const getPerformanceGraph = createAction('[Home] Get performance graph', (range: PerformanceFilterRange = PerformanceFilterRange.ALL) => ({
  range
}));
export const getPerformanceGraphSuccess = createAction(
  '[Home] Get performance graph success',
  props<{ graphData: PerformanceItem[]; range: PerformanceFilterRange }>()
);
export const getPerformanceGraphFailed = createAction('[Home] Get performance graph failed');
export const togglePerformanceGraphType = createAction('[Home] Toggle performance graph type', props<{ chartType: PerformanceType }>());

export const getAllReportingsStatus = createAction('[Home] Get all reportings status');

export const getAllReportingsStatusSuccess = createAction('[Home] Get all reportings status success', props<{ data: ThirdPartyServiceItem[] }>());

export const getGraphDataFail = createAction('[Home] Get graph data fail', props<{ account: CustomerAccountItem; error: boolean }>());

export const getImpactStories = createAction('[Home] Get impact stories');

export const getImpactStoriesSuccess = createAction('[Home] Get impact stories success', props<{ data: ImpactStoriesItem[] }>());
export const getImpactStoriesFail = createAction('[Home] Get impact stories fail');

export const getImpactStoryDetail = createAction('[Home] Get impact story detail', props<{ id: number }>());

export const getImpactStoryDetailSuccess = createAction('[Home] Get impact story detail success', props<{ data: ImpactStoryDetail }>());

export const getImpactMetrics = createAction('[Home] Get impact metrics');

export const getImpactMetricsSuccess = createAction('[Home] Get impact metrics success', props<{ data: ImpactMetric[] }>());
export const getImpactMetricsFail = createAction('[Home] Get impact metrics fail');

export const getCustomerNotifications = createAction('[Home] Get customer notifications');

export const getCustomerNotificationsSuccess = createAction('[Home] Get customer notifications success', props<{ data: Notification[] }>());

export const getCrowdInvestingData = createAction('[Home] Get crowd investing data');

export const getCrowdInvestingDataSuccess = createAction('[Home] Get crowd investing data success', props<{ data: CrowdInvestingValues }>());

export const getCompaniesStockPerformance = createAction('[Home] Get companies stock performance', props<{ account: CustomerAccountItem }>());

export const getCompaniesStockPerformanceSuccess = createAction(
  '[Home] Get companies stock performance success',
  props<{ account: CustomerAccountItem; data: CompaniesStockPerformance[] }>()
);

export const getCompaniesStockPerformanceFail = createAction(
  '[Home] Get companies stock performance fail',
  props<{ account: CustomerAccountItem }>()
);

export const getAccountStocks = createAction('[Home] Get account stocks', props<{ accountID: string }>());
export const getAccountStocksSuccess = createAction(
  '[Home] Get account stocks success',
  props<{ accountID: string; stocks: Stock[]; language: string }>()
);
export const getAccountStocksFail = createAction('[Home] Get account stocks fail');
export const checkAccountStocksLanguage = createAction('[Home] Check account stocks language');

export const sendEngagement = createAction('[Home] Send engagement action', props<{ action: boolean; id: number; rebalance_portfolio: boolean }>());

export const sendEngagementSuccess = createAction('[Home] Send engagement action success', props<{ data: EngagementDetail }>());

export const deleteEngagement = createAction('[Home] Delete engagement action', props<{ id: number }>());

export const deleteEngagementStatus = createAction('[Home] Delete engagement action status', props<{ success: boolean }>());

export const getSuperhumanSurvey = createAction('[Home] Get superhuman survey', props<{ nextQuestionID: number }>());

export const getSuperhumanSurveySuccess = createAction(
  '[Home] Get superhuman survey success',
  props<{ survey: SuperhumanQuestion[]; nextQuestionID: number }>()
);

export const getSuperhumanSurveyFailed = createAction('[Home] Get superhuman survey failed');

export const saveSuperhumanSurveyAnswers = createAction(
  '[Home] Save superhuman survey answers',
  props<{ nextQuestionID: number; answers: SuperhumanPutAnswer[]; submitted: boolean }>()
);

export const setCurrentSuperhumanSurveyID = createAction('[Home] Set superhuman survey ID', props<{ id: number }>());

export const markSuperhumanNotificationAsSeen = createAction('[Home] Mark superhuman notification as seen', props<{ customerID: number }>());

export const getAccountOptions = createAction('[Home] Get account option');
export const getAccountOptionsSuccess = createAction('[Home] Get account option success', props<{ data: AccountOptions }>());
export const getAccountOptionsFail = createAction('[Home] Get account option fail');
export const setAccountOptions = createAction('[Home] Set account option', props<{ option: string }>());
export const setAccountOptionsSuccess = createAction('[Home] Set account option success', props<{ data: AccountOptions }>());
export const setAccountOptionsFail = createAction('[Home] Set account option fail');

export const getSavingsPlanSummary = createAction('[Home] Get savings plan summary');
export const getSavingsPlanSummarySuccess = createAction('[Home] Get savings plan summary success', props<{ data: SavingsPlanSummary }>());
export const getSavingsPlanSummaryFail = createAction('[Home] Get savings plan summary fail', props<{ data: null }>());

export const getPerformanceSummary = createAction('[Home] Get performance summary');
export const getPerformanceSummarySuccess = createAction('[Home] Get performance summary success', props<{ data: PerformanceSummary }>());
export const getPerformanceSummaryFail = createAction('[Home] Get performance summary fail');

export const getBalanceSheet = createAction('[Home] Get balance sheet');
export const getBalanceSheetSuccess = createAction('[Home] Get balance sheet success', props<{ data: BalanceSheet }>());
export const getBalanceSheetFail = createAction('[Home] Get balance sheet fail');

export const getPromoCampaigns = createAction('[Home] Get promo campaigns');
export const getPromoCampaignsSuccess = createAction('[Home] Get promo campaigns success', props<{ data: PromoCampaign }>());
export const getPromoCampaignsFail = createAction('[Home] Get promo campaigns fail');
export const setCampaignParticipation = createAction('[Home] Set Campaign participation', props<{ participation: boolean; id: number }>());
export const setCampaignParticipationSuccess = createAction('[Home] Set Campaign participation success', props<{ data: PromoCampaignItem[] }>());
