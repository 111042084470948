import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Stock } from '@shared/models/Home';

@Component({
  selector: 'app-stock-box',
  templateUrl: './stock-box.component.html',
  styleUrls: ['./stock-box.component.scss']
})
export class StockBoxComponent {
  @Input() stock!: Stock;
  @Input() currency!: string;
  @Input() first: boolean;
  @Input() last: boolean;
  @Output() viewCompanyDetail = new EventEmitter<Stock>();
}
