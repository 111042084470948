// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-content {
  --padding-start: var(--spacing-6);
  --padding-end: var(--spacing-6);
  --padding-bottom: var(--spacing-8);
}

.project-image {
  border-radius: var(--radius-5);
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  margin-bottom: var(--spacing-8);
  width: 100%;
}
.project-image > img {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

h3 {
  margin-top: var(--spacing-2);
  margin-bottom: var(--spacing-8);
}

h4 {
  font-weight: var(--fw-700);
  font-size: var(--fs-4);
}

.description {
  display: flex;
  gap: var(--spacing-6);
  flex-direction: column;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
