// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  margin: 24px 0 24px;
}

.header h1 {
  font-family: var(--font-secondary);
  font-weight: var(--fw-600);
  font-size: var(--fs-5);
  line-height: 28px;
  margin-bottom: 0;
}
.header p {
  font-weight: var(--fw-500);
  font-size: var(--fs-2);
  letter-spacing: 0.005em;
  color: var(--ion-color-new-grey);
}

.cta {
  display: block;
  margin-top: var(--spacing-6);
}

.grow-projects {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: minmax(224px, auto);
  gap: var(--spacing-4);
}

.grow-project {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;
}
.grow-project h4 {
  margin-top: var(--spacing-3);
}

.grow-project-image {
  border-radius: var(--radius-1);
  height: 0;
  overflow: hidden;
  padding-bottom: 66.6666666667%;
  position: relative;
  width: 100%;
}
.grow-project-image > img {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
