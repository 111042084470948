import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GrowProject } from '@inyova/grow';
import { isCustomer } from '@inyova/utils';
import { IonRouterOutlet, ModalController } from '@ionic/angular';

import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as InyovaGrowActions from '@grow/inyova-grow.actions';
import * as fromAccount from '@account/account.reducers';
import * as fromInyovaGrow from '@grow/inyova-grow.reducers';

import { LocalStorageKeys } from '@app/app.constants';
import { InyovaGrowImpactProjectModalComponent } from '@grow/modals';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home-impact-grow',
  styleUrls: ['./home-impact-grow.component.scss'],
  templateUrl: './home-impact-grow.component.html'
})
export class HomeImpactGrowComponent implements OnInit, OnDestroy {
  protected readonly onDestroy$ = new Subject<void>();
  growProjectsLoaded$: Observable<boolean>;
  growProjects: GrowProject[];

  hasGrowAccount = false;
  canOpenGrowAccountInApp = false;

  customer: any;

  constructor(
    private store: Store,
    private router: Router,
    private modalController: ModalController,
    private routerOutlet: IonRouterOutlet
  ) {}

  ngOnInit(): void {
    this.store.pipe(select(fromAccount.selectCustomer), takeUntil(this.onDestroy$)).subscribe((customer) => {
      this.hasGrowAccount = customer.unified_accounts.some((account) => account.kind === 'interest' && isCustomer(account.step_status));
      this.canOpenGrowAccountInApp = customer.saxo_bank_onboarding_done;
      this.customer = customer;
    });
    this.store.dispatch(InyovaGrowActions.getGrowProjects());
    this.growProjectsLoaded$ = this.store.select(fromInyovaGrow.selectGrowProjectsLoaded);
    this.store.pipe(select(fromInyovaGrow.selectGrowInvestedProjects), takeUntil(this.onDestroy$)).subscribe((projects) => {
      this.growProjects = projects;
    });
  }

  goToGrowProjects() {
    void this.router.navigate(['/tabs/grow-performance']);
  }

  goToGrowOnboarding() {
    if (this.canOpenGrowAccountInApp) {
      this.router.navigate(['/grow/intro']);
    } else {
      this.goToGrowWebOnboarding();
    }
  }

  goToGrowWebOnboarding(): void {
    const url = `${environment.customersApp.ch}/?customer_token=${localStorage.getItem(LocalStorageKeys.AUTHENTICATION_TOKEN)}&redirect=grow/intro`;
    window.open(url, '_system');
  }

  async showProjectImpactDetails(project: GrowProject) {
    const modal = await this.modalController.create({
      component: InyovaGrowImpactProjectModalComponent,
      canDismiss: true,
      presentingElement: this.routerOutlet.nativeEl,
      componentProps: {
        title: project.title,
        image: project.image,
        impactDescription: project.impact_description
      }
    });
    return modal.present();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
