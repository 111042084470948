// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.review-header > h1 {
  margin-bottom: var(--spacing-4);
}
.review-header > p {
  margin-bottom: var(--spacing-8);
}

.review-box {
  margin-bottom: var(--spacing-8);
}
.review-box > h2 {
  margin-top: var(--spacing-0);
  margin-bottom: var(--spacing-3);
}
.review-box .ds-rows-white {
  margin-bottom: var(--spacing-2);
}

.review-general ul {
  padding-left: var(--spacing-6);
  list-style-type: square;
  margin-bottom: var(--spacing-1);
}
.review-general ul li {
  color: var(--color-core-neutral-8);
  font-size: var(--fs-2);
  line-height: var(--flh-3);
  font-weight: var(--fw-500);
  letter-spacing: var(--fls-5);
  margin-bottom: var(--spacing-5);
}
.review-general ul li:last-child {
  margin-bottom: var(--spacing-0);
}

.content > a {
  color: var(--color-core-neutral-10);
  display: flex;
  gap: var(--spacing-1);
}

.waiver {
  background-color: var(--color-core-neutral-0);
  border-radius: var(--radius-2);
  border: 1px solid var(--color-core-neutral-4);
  overflow: hidden;
  overflow-y: auto;
  padding: var(--spacing-2) var(--spacing-3);
  height: 338px;
}
.waiver > p {
  margin-bottom: var(--spacing-4);
  white-space: pre-wrap;
}

app-inyova-grow-project-doc {
  display: block;
  width: 100%;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
