import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AmplitudeService } from '@inyova/inyova-shared';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as fromHome from '@home/reducers';

import { SavingsPlanSummary } from '@app/shared/models/Account';
import { TrackingService } from '@app/shared/services/tracking.service';

@Component({
  selector: 'app-track-deposit',
  styleUrls: ['./home-track-deposit.component.scss'],
  templateUrl: './home-track-deposit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeTrackDepositComponent {
  readonly $savingPlan: Observable<SavingsPlanSummary> = this.store.select(fromHome.selectSavingsPlanSummary);
  @Input() currency!: string;
  @Input() kind: '3b' | '3a' | 'kid' | 'ci' | 'interest' | 'grow';
  showInfo = false;
  year = new Date().getFullYear();

  types = {
    on_track: 'info',
    off_track: 'warning',
    not_set: 'critical'
  };

  constructor(
    private router: Router,
    private store: Store,
    private trackingService: TrackingService,
    private amplitudeService: AmplitudeService
  ) {}

  manage(status: string) {
    const pageURL = status === 'off_track' ? 'invest-details' : 'invest-money';
    this.trackingService.trackActivity(`[Performance] Home deposit widget Clicked: ${pageURL}`);
    this.amplitudeService.trackEvent('Savings Plan Opened', { action_source: 'on_page_link', kind: this.kind });
    void this.router.navigate(['tabs', 'account', pageURL]);
  }

  toggleInfo(event: Event) {
    event.stopPropagation();
    this.showInfo = !this.showInfo;
  }
}
