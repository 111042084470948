import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-strategy-bonds-info',
  styleUrls: ['./strategy-bonds-info.component.scss'],
  templateUrl: './strategy-bonds-info.component.html'
})
export class StrategyBondsInfoComponent {
  @Input() bondId: number;
  @Input() flagSrc: string;
  @Input() percentage: number;

  constructor(private modalController: ModalController) {}

  dismiss() {
    this.modalController.dismiss({
      dismissed: true
    });
  }
}
