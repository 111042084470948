// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-content {
  --padding-start: var(--spacing-6);
  --padding-end: var(--spacing-6);
  --padding-bottom: var(--spacing-25);
}

h2 {
  margin-bottom: var(--spacing-8);
}

.explanation-list {
  margin: var(--spacing-6) 0 var(--spacing-8);
}
.explanation-list > h2 {
  margin: 0;
}
.explanation-list > .ds-list {
  margin-bottom: var(--spacing-8);
}

app-ds-button[variant=primary] {
  display: block;
  margin-bottom: var(--spacing-4);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
