import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class InyovaGrowValidator {
  static validateAmountStep(increment: number, minFunding: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }

      if (control.value === minFunding) {
        return null;
      }

      if (control.value % increment !== 0) {
        return { incrementError: true };
      }

      return null;
    };
  }
}
