import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Customer } from '@inyova/models';
import { TranslateService } from '@ngx-translate/core';
import { Jsona } from 'jsona';
import { TJsonApiBody } from 'jsona/lib/JsonaTypes';

import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as fromRoot from '@app/app.reducers';

import {
  ABTest,
  AccountDocument,
  GrowDocument,
  AccountLibertyDocument,
  AccountLibertyMeta,
  AccountOptions,
  CreatedPromotions,
  CustomerPersonalDetails,
  Promotions,
  SavingsPlanSummary,
  StaticDocument,
  Transaction
} from '@shared/models/Account';
import { CiOverviewResponse } from '@shared/models/CrowdInvestor';
import {
  CompaniesStockPerformance,
  EngagementDetail,
  ImpactMetric,
  ImpactStoriesItem,
  ImpactStoryDetail,
  MobileReportsModel,
  PerformanceFilterRange,
  PerformanceItem,
  PromoCampaign,
  PromoParticipation,
  ReportEndValues,
  Stock,
  SuperhumanPutAnswer,
  SuperhumanQuestion,
  ThirdPartyServiceItem
} from '@shared/models/Home';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DashboardResources {
  private readonly URL: string;
  private readonly URL_V3: string;
  private readonly URL_V4: string;
  private accountID: string;
  private jsona = new Jsona();

  constructor(
    private http: HttpClient,
    private store: Store,
    private translateService: TranslateService
  ) {
    this.store.pipe(select(fromRoot.selectSelectedAccount)).subscribe((account) => (this.accountID = account?.id));
    this.URL = `${environment.apiUrl}`;
    this.URL_V3 = `${environment.apiUrlV3}`;
    this.URL_V4 = `${environment.apiUrlV4}`;
  }

  checkPassword(data: { password: string }): Observable<{ status: string }> {
    return this.http.post<{ status: string }>(`${this.URL}/validate/password`, data);
  }

  contactSupport(data: { message: string; account_type?: string }): Observable<{ status: string }> {
    return this.http.post<{ status: string }>(`${this.URL}/support`, { body: data });
  }

  getABTests(): Observable<{ data: ABTest[]; meta: [] }> {
    return this.http.get<{ data: ABTest[]; meta: [] }>(`${environment.apiUrlV3}/ab_tests`);
  }

  getCustomer(): Observable<Customer> {
    return this.http.get<Customer>(`${this.URL}`);
  }

  getDocuments3A(size: number, pageIndex: number): Observable<{ data: AccountLibertyDocument[]; meta: AccountLibertyMeta }> {
    return this.http.get<{ data: AccountLibertyDocument[]; meta: AccountLibertyMeta }>(
      `${this.URL_V3}/liberty_documents?sort=-document_date&stats[total]=count&page[size]=${size}&page[number]=${pageIndex}`
    );
  }

  getCustomerDocuments(): Observable<{ data: AccountDocument[]; meta: [] }> {
    return this.http.get<{ data: AccountDocument[]; meta: [] }>(`${this.URL_V3}/customer_documents`);
  }

  getDocumentsGrow(): Observable<GrowDocument[]> {
    return this.http.get<TJsonApiBody>(`${this.URL_V3}/inyova_interest/impact_project_documents`).pipe(
      map((response) => {
        return (this.jsona.deserialize({ ...response }) as GrowDocument[]).map((document) => {
          if (document.title) {
            return document;
          }

          let key = '';
          switch (document.type) {
            case 'terms_conditions_sheet':
              key = 'termsConditionsSheet';
              break;
            case 'term_sheet':
              key = 'termSheet';
              break;
            default:
              key = 'factSheet';
          }

          return {
            ...document,
            title: this.translateService.instant(`GROW.project.documents.${key}`)
          };
        });
      })
    );
  }

  getStaticDocuments(): Observable<{ data: StaticDocument[]; meta: [] }> {
    return this.http.get<{ data: StaticDocument[]; meta: [] }>(`${this.URL_V3}/static_documents`);
  }

  getImpactStories(): Observable<{ data: ImpactStoriesItem[] }> {
    return this.http.get<{ data: ImpactStoriesItem[] }>(`${this.URL_V3}/impact_stories`);
  }

  getImpactStoryDetail(id: number): Observable<{ data: ImpactStoryDetail }> {
    return this.http.get<{ data: ImpactStoryDetail }>(`${this.URL_V3}/impact_stories/${id}`);
  }

  getEndValues(accountID: string): Observable<{ customer: { end_values: ReportEndValues } }> {
    return this.http.get<{ customer: { end_values: ReportEndValues } }>(`${this.URL}/accounts/${accountID}/reports/end-values`);
  }

  getPerformanceGraph(accountID: string, range: PerformanceFilterRange): Observable<PerformanceItem[]> {
    return this.http
      .get<{
        data: { type: string; id: string; attributes: { reporting: PerformanceItem[] } }[];
      }>(`${this.URL_V3}/performance_reports/${accountID}?range=${range}`)
      .pipe(map(({ data }) => data[0].attributes.reporting));
  }

  getReports(accountID: string): Observable<MobileReportsModel> {
    return this.getPerformanceGraph(accountID, PerformanceFilterRange.ALL).pipe(
      map((reporting) => ({
        customer: {
          reporting
        }
      }))
    );
  }

  getPromotions(): Observable<Promotions> {
    return this.http.get<Promotions>(
      `${environment.apiUrlV3}/customer_promotions?include=promotion&active_rewards=true&pending_rewards=true&referral_rewards=true`
    );
  }

  fetchTransactions(isGrowActive: boolean, growAccountId: string): Observable<{ report_transactions: Transaction[] }> {
    if (isGrowActive) {
      return this.http.get<TJsonApiBody>(`${this.URL_V3}/reporting/transactions/${growAccountId}`).pipe(
        map((response) => {
          const transactions: Transaction[] = this.jsona.deserialize(response) as Transaction[];
          return { report_transactions: transactions };
        })
      );
    }

    return this.http.get<{ report_transactions: Transaction[] }>(`${this.URL}/accounts/${this.accountID}/report-transactions`);
  }

  sendVerifyPhoneCode(): Observable<{ code: number; message: string; status: string }> {
    return this.http.post<{ code: number; message: string; status: string }>(`${environment.apiUrl}/phone_verification/send_code`, {
      manual_resend: true
    });
  }

  verifyPhoneNumberCode(code: string): Observable<{ code: number; message: string; status: string }> {
    return this.http.post<{ code: number; message: string; status: string }>(`${environment.apiUrl}/phone_verification/verify_code`, {
      otp_code_token: code
    });
  }

  getAllReportingsStatus(): Observable<{ data: ThirdPartyServiceItem[] }> {
    return this.http.get<{ data: ThirdPartyServiceItem[] }>(
      `${this.URL_V3}/flipper_gates?filter[feature_key]=reporting_de_3b,reporting_ch_3a,reporting_ch_3b`
    );
  }

  redeemPromocode(code: string): Observable<CreatedPromotions> {
    const payload = {
      data: { type: 'promotion_redemptions', attributes: { code } }
    };
    return this.http.post<CreatedPromotions>(`${environment.apiUrlV3}/promotion_redemptions?include=promotions`, payload);
  }

  updateCustomer(personalDetails: Partial<CustomerPersonalDetails>): Observable<{ data: { attributes: CustomerPersonalDetails } }> {
    return this.http.put<{ data: { attributes: CustomerPersonalDetails } }>(`${environment.apiUrlV3}/personal_info`, {
      data: { type: 'personal_infos', id: 0, attributes: personalDetails }
    });
  }

  getImpactMetrics(): Observable<{ data: ImpactMetric[] }> {
    return this.http.get<{ data: ImpactMetric[] }>(`${this.URL_V3}/impact_metrics`);
  }

  getCompaniesStockPerformance(accountID: string): Observable<{ data: CompaniesStockPerformance[] }> {
    return this.http.get<{ data: CompaniesStockPerformance[] }>(`${this.URL_V3}/current_portfolio_positions?filter[account_id]=${accountID}`);
  }

  getAccountStocks(accountID: string): Observable<{ stocks: Stock[] }> {
    return this.http.get<{ stocks: Stock[] }>(`${this.URL_V4}/reporting/accounts/${accountID}/fund_breakdown`);
  }

  getCrowdinvestingData(): Observable<CiOverviewResponse> {
    return this.http.get<CiOverviewResponse>(`${environment.apiUrlV3}/crowd_investor_accounts`);
  }

  sendEngagement(action: boolean, id: number, rebalance: boolean): Observable<EngagementDetail> {
    const payload = {
      data: {
        type: 'campaign_likes',
        attributes: {
          liked: action,
          impact_story_id: id,
          rebalance_portfolio: rebalance
        }
      }
    };
    return this.http.put<EngagementDetail>(`${environment.apiUrlV3}/campaign_likes/${id}`, payload);
  }

  deleteEngagement(id: number): Observable<EngagementDetail> {
    return this.http.delete<EngagementDetail>(`${environment.apiUrlV3}/campaign_likes/${id}`);
  }

  getSuperHumanSurvey(): Observable<{ id: number; name: string; survey_questions: SuperhumanQuestion[] }> {
    return this.http.get<{ id: number; name: string; survey_questions: SuperhumanQuestion[] }>(
      `${environment.apiUrl}/accounts/${this.accountID}/survey/superhuman_v2`
    );
  }

  saveSuperHumanSurvey(payload: { answers: SuperhumanPutAnswer[]; submitted: boolean }): Observable<{ success: boolean }> {
    const finalPayload = {
      ...payload,
      answered_from: 'mobile-app'
    };
    return this.http.post<{ success: boolean }>(`${environment.apiUrl}/survey/superhuman_v2/answers`, finalPayload);
  }

  ignoreSuperhumanSurvey(): Observable<{ success: boolean }> {
    return this.http.post<{ success: boolean }>(`${environment.apiUrl}/survey/superhuman_v2/ignore`, {});
  }

  getAccountOptions(id: string): Observable<{ data: AccountOptions }> {
    return this.http.get<{ data: AccountOptions }>(`${environment.apiUrlV3}/account_option/${id}`);
  }

  setAccountOptions(option: string, id: string): Observable<{ data: AccountOptions }> {
    const payload = {
      data: {
        type: 'account_options',
        attributes: {
          account_id: id,
          interest_for_portfolio_review: option === 'portfolio_review',
          interest_for_deposit_control: option === 'deposit_control'
        }
      }
    };

    return this.http.put<{ data: AccountOptions }>(`${environment.apiUrlV3}/account_option/${id}`, payload);
  }

  getSavingsPlanSummary(id: string): Observable<{ data: { type: ''; attributes: SavingsPlanSummary } }> {
    return this.http.get<{ data: { type: ''; attributes: SavingsPlanSummary } }>(`${environment.apiUrlV3}/savings_plan_summary/${id}`);
  }

  getPromoCampaigns(): Observable<PromoCampaign> {
    return this.http.get<PromoCampaign>(`${this.URL_V3}/promotions/promotion_campaigns?include=deposit_promotions`);
  }

  setPromoParticipation(participation: boolean, id: number): Observable<{ data: PromoParticipation }> {
    const payload = {
      data: {
        type: 'promotion_campaign_customers',
        attributes: {
          opted_in: participation,
          promotion_campaign_id: id
        }
      }
    };

    return this.http.put<{ data: PromoParticipation }>(`${environment.apiUrlV3}/promotions/promotion_campaign_customers`, payload);
  }
}
