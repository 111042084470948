/* eslint-disable no-restricted-properties */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { AmplitudeService } from '@inyova/inyova-shared';
import { CustomerAccount } from '@inyova/models';
import { IonRouterOutlet, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

import { select, Store } from '@ngrx/store';
import { noop, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as AccountActions from '@account/account.actions';
import * as StrategyActions from '@strategy/strategy.actions';
import * as fromStrategy from '@strategy/strategy.reducers';

import { MAXIMUM_3A_CONTRIBUTION } from '@app/app.constants';
import { StatusBarStylingService } from '@app/shared/services/status-bar-styling.service';
import { GeneralConfirmModalComponent } from '@shared/modals';
import { SavingsPlanSocialProof } from '@shared/models/Account';
import { EstimatedValues, StackedAreaChartData, StackedAreaChartDataItem } from '@shared/models/Shared';
import { InvestmentData } from '@shared/models/Strategy';
import { TrackingService } from '@shared/services/tracking.service';

import { getAggregatedValues, getExpectedValues } from './asset-chart.utils';

@Component({
  selector: 'app-asset-chart-3a',
  templateUrl: 'asset-chart-3a.component.html',
  styleUrls: ['asset-chart.component.scss']
})
export class AssetChart3AComponent implements OnChanges, OnDestroy {
  protected readonly onDestroy$ = new Subject<void>();
  @Input() appLocation: string;
  @Input() contributionLevel: string;
  @Input() currentAccount: CustomerAccount;
  @Input() customerInvestment: InvestmentData;
  @Input() riskLevel: string;
  @Input() language: string;
  @Input() isStrategy: boolean;
  @Input() savingsPlanSocialProof: SavingsPlanSocialProof;
  annualDeposit: number;
  chartData: StackedAreaChartData;
  currentYear = new Date().getFullYear();
  expectedPercentages: {
    loss: number;
    expected: number;
    good: number;
  };

  estimatedValues: EstimatedValues;
  minimumInput: number;
  maximumInput: number;
  maximumDeposit: number;
  newInputValue: number;
  retirementYear: number;
  selectedChip = 0;
  showChartDetails = false;
  timeToRetirement: number;
  inputError: string;

  constructor(
    private modalController: ModalController,
    private routerOutlet: IonRouterOutlet,
    private store: Store,
    private trackingService: TrackingService,
    private translateService: TranslateService,
    private router: Router,
    private statusBarStyling: StatusBarStylingService,
    private amplitudeService: AmplitudeService
  ) {
    this.store.dispatch(StrategyActions.getRiskQuestions());
    this.store.pipe(select(fromStrategy.selectRetirementDetails), takeUntil(this.onDestroy$)).subscribe((retirementDetails) => {
      if (!retirementDetails) {
        return;
      }
      this.timeToRetirement = retirementDetails.timeToRetirement;
      this.retirementYear = retirementDetails.retirementYear;
      if (this.contributionLevel !== 'transfer_only') {
        this.changeEstimate();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.customerInvestment) {
      this.newInputValue = this.customerInvestment.monthly_investment;
      this.getMinMaxInvestment();
    }

    if (changes?.contributionLevel) {
      const currentYear = new Date().getFullYear();
      this.maximumDeposit = MAXIMUM_3A_CONTRIBUTION[currentYear][this.contributionLevel];
      this.annualDeposit = this.maximumDeposit;
      this.getMinMaxInvestment();

      if (this.contributionLevel !== 'transfer_only') {
        this.changeEstimate();
      }
    }
  }

  changeEstimate() {
    this.chartData = { columns: [], data: [] }; // clear data

    const expectedValues = getExpectedValues(this.appLocation, this.customerInvestment.stock_split);
    this.expectedPercentages = {
      loss: expectedValues.expectedLossRatio,
      expected: expectedValues.expectedReturnRatio,
      good: expectedValues.expectedPotentialRatio
    };
    const { expectedReturnRatioFinal, expectedSTDDevRatioFinal, expectedMonthlyReturn, confidenceInterval } = expectedValues;

    const currentYear = new Date().getFullYear();
    const total = this.customerInvestment.current_amount;
    const monthly3aInvestment = this.selectedChip === 0 ? this.newInputValue : this.newInputValue / 12;
    const yearly3aInvestment = this.selectedChip === 1 ? this.newInputValue : this.newInputValue * 12;

    let NAV = total;
    let NAVWithout = total;
    const firstYearData = { date: new Date(`01/01/${currentYear.toFixed()}`) } as StackedAreaChartDataItem;
    firstYearData['badWithout'] = total; // We initialize just the lowest line (stacked chart)
    firstYearData['expectedWithout'] = 0;
    firstYearData['goodWithout'] = 0;
    firstYearData['bad'] = 0;
    firstYearData['expected'] = 0;
    firstYearData['good'] = 0;
    this.chartData.data.push(firstYearData);

    for (let i = 1; i <= this.timeToRetirement * 12; i++) {
      let currentMonthDeposit = monthly3aInvestment;
      if (this.selectedChip === 1) {
        currentMonthDeposit = i % 12 === 0 ? yearly3aInvestment : 0;
      }

      const aggregatedValues = getAggregatedValues(
        i,
        expectedReturnRatioFinal,
        expectedSTDDevRatioFinal,
        confidenceInterval,
        expectedMonthlyReturn,
        NAV,
        NAVWithout,
        currentMonthDeposit
      );

      const { WCNav, WCNavWithout, BCNav, BCNavWithout } = aggregatedValues;
      NAV = aggregatedValues.NAV;
      NAVWithout = aggregatedValues.NAVWithout;

      if ((this.selectedChip === 0 && i % 12 === 0) || this.selectedChip === 1) {
        const newYearData = { date: new Date(`01/01/${(currentYear + i / 12).toFixed()}`) } as StackedAreaChartDataItem;

        // We add just differance between the last and new line (stacked chart)
        newYearData['badWithout'] = parseFloat(WCNavWithout.toFixed(0));
        newYearData['expectedWithout'] = parseFloat(NAVWithout.toFixed(0)) - parseFloat(WCNavWithout.toFixed(0));
        newYearData['goodWithout'] = parseFloat(BCNavWithout.toFixed(0)) - parseFloat(NAVWithout.toFixed(0));
        newYearData['bad'] = parseFloat(WCNav.toFixed(0)) - parseFloat(BCNavWithout.toFixed(0));
        newYearData['expected'] = parseFloat(NAV.toFixed(0)) - parseFloat(WCNav.toFixed(0));
        newYearData['good'] = parseFloat(BCNav.toFixed(0)) - parseFloat(NAV.toFixed(0));
        this.chartData.data.push(newYearData);
      }

      this.estimatedValues = {
        badWithout: WCNavWithout,
        expectedWithout: NAVWithout,
        goodWithout: BCNavWithout,
        bad: WCNav,
        expected: NAV,
        good: BCNav
      };
    }

    this.chartData.columns = ['date', 'badWithout', 'expectedWithout', 'goodWithout', 'bad', 'expected', 'good'];
  }

  track(event: string) {
    this.trackingService.trackActivity(event);
  }

  setChip(chip: number) {
    if (this.selectedChip === chip) return;
    this.selectedChip = chip;
    const savedMonthlyInvestment = this.customerInvestment.monthly_investment;
    this.newInputValue = this.selectedChip === 0 ? savedMonthlyInvestment : savedMonthlyInvestment * 12;
    this.getMinMaxInvestment();
    this.changeEstimate();
  }

  isGerman() {
    return this.language.includes('de-');
  }

  getTotalDeposit() {
    const multiplier = this.selectedChip === 0 ? 12 : 1;
    const reinvested = this.newInputValue * multiplier * this.timeToRetirement;
    return (this.customerInvestment?.total + reinvested).toFixed(0);
  }

  onChangeValue(value: number | null) {
    this.newInputValue = value;
    this.trackingService.trackActivity(`[Button] SP - input: ${this.newInputValue}`);
    this.changeEstimate();
  }

  onSetDeposit(value: number) {
    this.newInputValue = value;
    const newMonthlyValue = this.selectedChip === 0 ? this.newInputValue : this.newInputValue / 12;
    if (this.isInLimits()) {
      this.trackingService.trackActivity(`[Button] SP deposit: Update deposit ${newMonthlyValue}`);
      this.amplitudeService.trackEvent(`Savings Plan Engaged`, { savings_plan_ctas: 'savings_plan_saved', kind: '3a' });
      this.store.dispatch(
        AccountActions.updateSp({ data: newMonthlyValue, id: this.currentAccount.id, isWisher: this.currentAccount.is_wisher, is3a: true })
      );
      this.inputError = '';
    }
  }

  isInLimits() {
    this.getMinMaxInvestment();

    if (this.newInputValue >= this.minimumInput && this.newInputValue <= this.maximumInput) {
      return true;
    }

    this.trackingService.trackActivity(`[Button] SP deposit: Error out of boundries investment: ${this.newInputValue}`);

    if (this.newInputValue < this.minimumInput) {
      this.inputError = 'underMinimum';
    }

    if (this.newInputValue > this.maximumInput) {
      this.inputError = 'overMaximum';
    }

    return false;
  }

  getMinMaxInvestment() {
    this.minimumInput = 0;
    this.maximumInput = this.selectedChip ? this.maximumDeposit : this.maximumDeposit / 12;
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  async openLegalInfo() {
    this.statusBarStyling.setBackgroundColor('dialog');
    const modal = await this.modalController.create({
      component: GeneralConfirmModalComponent,
      componentProps: {
        title: this.translateService.instant(this.isStrategy ? 'sp.revamp.assetGraph.strategy.title' : 'sp.revamp.assetGraph.savings'),
        body: this.translateService.instant('sp.revamp.infoTooltip.legal')
      },
      cssClass: 'app-fullscreen',
      presentingElement: this.routerOutlet.nativeEl
    });
    void modal.onDidDismiss().then(() => {
      this.statusBarStyling.setBackgroundColor('base');
    });
    return modal.present();
  }

  contactSupport(): void {
    this.trackingService.trackActivity('[Link] Contact us (deposit delay note)');
    this.router.navigateByUrl('/tabs/account/support').finally(noop);
  }
}
