import { Injectable, Optional } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { ApiConfig } from '../../../models';

export class InyovaConfig {
  url: string;
  urlV3: string;
  urlV4: string;
}

@Injectable({ providedIn: 'root' })
export class InyovaConfigService {
  private url: string | null = null;
  private urlV3: string | null = null;
  private urlV4: string | null = null;
  private apiDataSource: BehaviorSubject<ApiConfig> = new BehaviorSubject<ApiConfig>({
    url: this.url,
    urlV3: this.urlV3,
    urlV4: this.urlV4,
    accountID: null
  });

  apiData: Observable<ApiConfig> = this.apiDataSource.asObservable();

  constructor(@Optional() public config?: InyovaConfig) {
    if (config) {
      this.url = config.url;
      this.urlV3 = config.urlV3;
      this.urlV4 = config.urlV4;
      this.setApiData(this.url, this.urlV3, this.urlV4, null);
    }
  }

  getApiData(): Observable<ApiConfig> {
    return this.apiData;
  }

  setApiData(url: string, urlV3: string, urlV4: string, accountID: string): void {
    this.apiDataSource.next({ url, urlV3, urlV4, accountID });
  }

  setAccountId(accountID: string | null) {
    this.setApiData(this.url, this.urlV3, this.urlV4, accountID);
  }
}
