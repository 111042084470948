// clipboard.service.ts
import { Injectable } from '@angular/core';
import { Clipboard } from '@capacitor/clipboard';

@Injectable({
  providedIn: 'root'
})
export class ClipboardService {
  async copyToClipboard(value: string): Promise<void> {
    await Clipboard.write({ string: value });
  }
}
