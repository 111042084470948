import { AfterViewInit, Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { Network } from '@capacitor/network';
import { SplashScreen } from '@capacitor/splash-screen';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { ActionSheetController, IonRouterOutlet, LoadingController, ModalController, Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { TranslateService } from '@ngx-translate/core';
import { lt } from 'semver';

import { Store } from '@ngrx/store';
import { BehaviorSubject, Subject, noop } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as PublicActions from '@public/public.actions';

import { IonicStorageKeys, Languages, LocalStorageKeys } from '@app/app.constants';
import { StatusBarStylingService } from '@app/shared/services/status-bar-styling.service';
import { ResetPasswordComponent, SignupRedirectComponent } from '@public/modals';
import { DayTime, LoginType } from '@public/public.constants';
import { State } from '@shared/models/State';
import { AuthService } from '@shared/services/auth.service';
import { LanguageService } from '@shared/services/language.service';
import { RemoteConfigService } from '@shared/services/remote-config.service';
import { ToastService } from '@shared/services/toast.service';
import { environment } from 'src/environments/environment';

import packageJSON from '../../../../../package.json';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnDestroy, AfterViewInit, OnInit {
  biometricLoginPref: boolean;
  biometricToken: string;
  dayTime: DayTime;
  email: string;
  entered = false;
  disabledBiometric = false;
  firstName: string;
  isNewAppVersionAvailable = false;
  isStagingEnv = environment && environment.name !== 'production';
  loginForm: FormGroup;
  LoginType = LoginType;
  loginType: LoginType;
  passwordVisible = false;
  thirdParties = {
    deviceInfo: null,
    internetConnection: null,
    latestRequiredAppVersion: null,
    maintenance: null
  };

  private readonly onDestroy$ = new Subject<void>();
  private readonly onDestroyThirdParties$ = new Subject<void>();
  private readonly areThirdPartiesInitialised$ = new BehaviorSubject<boolean>(false);

  constructor(
    private authService: AuthService,
    private actionSheetController: ActionSheetController,
    private device: Device,
    private fb: FormBuilder,
    private loadingController: LoadingController,
    private modalController: ModalController,
    private platform: Platform,
    private remoteConfigService: RemoteConfigService,
    private route: ActivatedRoute,
    private zone: NgZone,
    private routerOutlet: IonRouterOutlet,
    private storage: Storage,
    private languageService: LanguageService,
    private translateService: TranslateService,
    private toastService: ToastService,
    private store: Store<State>,
    private statusBarStyling: StatusBarStylingService
  ) {}

  async changeLanguage() {
    const actionSheet = await this.actionSheetController.create({
      buttons: [
        {
          text: 'English',
          handler: () => {
            this.languageService.setLanguage(Languages.EN_CH);
          }
        },
        {
          text: 'Deutsch',
          handler: () => {
            this.languageService.setLanguage(Languages.DE_CH);
          }
        },
        {
          text: 'Français',
          handler: () => {
            this.languageService.setLanguage(Languages.FR_CH);
          }
        },
        {
          text: this.translateService.instant('SHARED.actions.cancel'),
          role: 'cancel'
        }
      ]
    });
    await actionSheet.present();
  }

  hasError(controlName: string, errorType: string) {
    if (!this.loginForm.controls[controlName]) {
      return false;
    }
    return this.loginForm.controls[controlName].touched && this.loginForm.controls[controlName].hasError(errorType);
  }

  getCurrentLang() {
    if (this.translateService && this.translateService.currentLang) {
      return this.translateService.currentLang.slice(0, 2);
    }
    return '';
  }

  getProvider(): void {
    const emailControl = this.loginForm.get('login');

    if (emailControl.invalid) {
      return emailControl.markAsTouched();
    }

    void this.startLoader(false);
    this.authService.getProvider(emailControl.value).subscribe(
      (res) => {
        void this.loadingController.dismiss();
        if (res && res.provider === 'google') {
          void this.loginWithOauth();
        }
        this.email = emailControl.value;
        this.loginType = res.provider;
      },
      () => {
        void this.loadingController.dismiss();
        this.loginType = LoginType.EMAIL;
        this.email = emailControl.value;
      }
    );
  }

  ionViewDidEnter(): void {
    this.entered = true;
    void this.remoteConfigService.fetchData();
    this.storage
      .get(IonicStorageKeys.BIOMETRIC_LOGIN_PREF)
      .then((value) => {
        if (value) {
          setTimeout(() => {
            this.autoLoginWithBiometric();
          }, 1500);
        }
        this.biometricLoginPref = value;
      })
      .catch(noop);
    // Set background on Android
    this.statusBarStyling.setBackgroundColor('login');
  }

  ionViewWillEnter(): void {
    void this.storage.get(IonicStorageKeys.LOGIN_TYPE).then((value) => (this.loginType = value));
    void this.storage.get(IonicStorageKeys.EMAIL).then((value) => (this.email = value));
    void this.storage.get(IonicStorageKeys.BIOMETRIC_TOKEN).then((value) => (this.biometricToken = value));
    void this.storage.get(IonicStorageKeys.FIRST_NAME).then((value) => (this.firstName = value));
  }

  ionViewDidLeave(): void {
    this.entered = false;
    this.biometricLoginPref = false;
    this.disabledBiometric = false;
    // Set background on Android
    this.statusBarStyling.setBackgroundColor('base');
    this.loginForm.reset();
  }

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      login: ['', [Validators.email, Validators.required]],
      password: ['', Validators.required]
    });
    this.route.queryParams.pipe(takeUntil(this.onDestroy$)).subscribe((params) => {
      if (params.customer_token) {
        this.store.dispatch(PublicActions.simulateCustomer({ token: params.customer_token }));
      }
    });
    this.getDayTime();
    if (Capacitor.isNativePlatform()) {
      void App.getInfo()
        .then((value) => {
          this.thirdParties.deviceInfo = value;
          this.updateThirpartiesValues();
        })
        .catch(() => {
          this.setCurrentVersion();
          this.updateThirpartiesValues();
        });
    } else {
      this.setCurrentVersion();
    }
    void Network.getStatus().then((res) => {
      this.thirdParties.internetConnection = res.connected;
    });
    this.remoteConfigService.remoteConfig.subscribe((value) => {
      this.zone.run(() => {
        if (environment.name === 'production') {
          this.thirdParties.maintenance = value.productionMaintenance;
          this.thirdParties.latestRequiredAppVersion = value.productionLatestRequiredAppVersion;
          this.updateThirpartiesValues();
          return;
        }
        this.thirdParties.maintenance = value.developmentMaintenance;
        this.thirdParties.latestRequiredAppVersion = value.developmentLatestRequiredAppVersion;
        this.updateThirpartiesValues();
      });
    });

    if (Capacitor.isNativePlatform()) {
      this.storage.remove(IonicStorageKeys.HIDE_ODD_MODAL);
    }
  }

  login(): void {
    if (!this.loginType) {
      this.getProvider();
      return;
    }

    if (this.loginType && this.loginType === LoginType.EMAIL) {
      this.loginForm.get('login').setValue(this.email);
    }

    if (this.loginForm.valid) {
      void this.startLoader(true);
      this.store.dispatch(PublicActions.customerSignIn(this.loginForm.getRawValue() as { login: string; password: string }));
    } else {
      this.loginForm.markAllAsTouched();
    }
  }

  loginWithBiometric(): void {
    this.authService.validateBiometric(this.device.uuid, this.biometricToken);
  }

  autoLoginWithBiometric(): void {
    this.areThirdPartiesInitialised$.pipe(takeUntil(this.onDestroyThirdParties$)).subscribe((value) => {
      if (
        value &&
        !this.thirdParties.maintenance &&
        !this.isNewAppVersionAvailable &&
        this.thirdParties.internetConnection &&
        this.biometricLoginPref &&
        !this.disabledBiometric
      ) {
        this.onDestroyThirdParties$.next();
        this.onDestroyThirdParties$.complete();
        this.authService.validateBiometric(this.device.uuid, this.biometricToken);
        // Workaround on Android devices, because of Biometric login plugin
        this.disabledBiometric = true;
      }
    });
  }

  async loginWithOauth(): Promise<void> {
    try {
      void this.startLoader(true);
      GoogleAuth.initialize();
      const user = await GoogleAuth.signIn();
      await this.authService.loginGoogle(user.authentication.idToken);
      void this.loadingController.dismiss();
    } catch (err) {
      if (err.status === 401) {
        void this.toastService.error(this.translateService.instant('SHARED.errors.googleLogin'));
      } else {
        void this.toastService.error(this.translateService.instant('SHARED.errors.something'));
      }
      void this.loadingController.dismiss();
    }
  }

  ngAfterViewInit(): void {
    void this.platform.ready().then(() => {
      void SplashScreen.hide({
        fadeOutDuration: 0
      });
    });
    localStorage.removeItem(LocalStorageKeys.ACCESS_TOKEN);
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.loginForm.reset();
  }

  async openSignupRedirect() {
    this.statusBarStyling.setBackgroundColor('dialog');
    const modal = await this.modalController.create({
      component: SignupRedirectComponent,
      cssClass: 'app-fullscreen',
      presentingElement: this.routerOutlet.nativeEl
    });
    void modal.onDidDismiss().then(() => {
      this.statusBarStyling.setBackgroundColor('base');
    });
    return modal.present();
  }

  async resetPassword() {
    this.statusBarStyling.setBackgroundColor('dialog');
    const modal = await this.modalController.create({
      component: ResetPasswordComponent,
      cssClass: 'app-fullscreen',
      presentingElement: this.routerOutlet.nativeEl
    });
    void modal.onDidDismiss().then(() => {
      this.statusBarStyling.setBackgroundColor('base');
    });
    return modal.present();
  }

  async switchCustomer() {
    // Finish full logout for customers with Google provider
    if (this.loginType === LoginType.GOOGLE) {
      try {
        await GoogleAuth.signOut();
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('switchCustomer', error);
      }
    }
    void this.authService.removeCustomerNotifications(this.device.uuid);
    this.loginForm.reset();
    this.biometricToken = undefined;
    this.biometricLoginPref = undefined;
    this.firstName = undefined;
    this.loginType = undefined;
    void this.storage.clear();
  }

  private async startLoader(potentiallyLongRequest = false) {
    const loading = await this.loadingController.create({ duration: potentiallyLongRequest ? 12000 : 3000 });
    await loading.present();
  }

  private getDayTime() {
    const curHr = new Date().getHours();

    if (curHr < 12) {
      this.dayTime = DayTime.MORNING;
    } else if (curHr < 18) {
      this.dayTime = DayTime.AFTERNOON;
    } else {
      this.dayTime = DayTime.EVENING;
    }
  }

  private updateThirpartiesValues(): void {
    const deviceAppVersion = this.thirdParties.deviceInfo?.version;
    if (!deviceAppVersion) return;
    this.isNewAppVersionAvailable = this.thirdParties.latestRequiredAppVersion && lt(deviceAppVersion, this.thirdParties.latestRequiredAppVersion);
    this.areThirdPartiesInitialised$.next(
      this.thirdParties.deviceInfo !== null &&
        this.thirdParties.internetConnection !== null &&
        this.thirdParties.latestRequiredAppVersion !== null &&
        this.thirdParties.maintenance !== null
    );
  }

  private setCurrentVersion(): void {
    this.thirdParties.deviceInfo = {
      version: packageJSON.version,
      build: environment.name
    };
  }
}
